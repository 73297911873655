import React from "react";
import { Link } from "react-router-dom";
import errorImg from "../assets/errorImg.png";

const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <img src={errorImg} alt="" width="500px" />
      <p className="text-2xl text-gray-600 mb-8">Oops! Page not found.</p>
      <Link
        to="/"
        className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
      >
        Go back to homepage
      </Link>
    </div>
  );
};

export default PageNotFound;
