import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { HashLink } from "react-router-hash-link";
import toast, { Toaster } from "react-hot-toast";
import {
  Shield,
  Sun,
  Sparkles,
  IndianRupee,
  Droplets,
  Eye,
  ChevronDown,
  Send,
} from "lucide-react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import WindowsHoverEffect from "../components/WindowsHoverEffect";
import backgroundImage from "../assets/person-working-car-wrapping.webp";
import { Helmet } from "react-helmet-async";

const FadeInWhenVisible = ({ children }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 50 },
      }}
    >
      {children}
    </motion.div>
  );
};

const PaintProtectionFilmPage = () => {
  const benefits = [
    { text: "Protects against rock chips and road debris", icon: Shield },
    { text: "Preserves paint from UV rays and oxidation", icon: Sun },
    { text: "Self-healing properties for minor scratches", icon: Sparkles },
    { text: "Enhances resale value of your vehicle", icon: IndianRupee },
    { text: "Resistant to stains and chemical etching", icon: Droplets },
    { text: "Virtually invisible protection", icon: Eye },
  ];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    carModel: "",
    date: "",
    message: "",
  });

  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [dateError, setDateError] = useState("");

  useEffect(() => {
    // Set the minimum date to today
    const today = new Date();
    const minDateString = today.toISOString().split("T")[0];
    setMinDate(minDateString);

    // Set the maximum date to 6 months from now
    const maxDate = new Date(today.setMonth(today.getMonth() + 6));
    const maxDateString = maxDate.toISOString().split("T")[0];
    setMaxDate(maxDateString);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "date") {
      validateDate(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateDate = (dateString) => {
    const selectedDate = new Date(dateString);
    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);

    if (isNaN(selectedDate.getTime())) {
      setDateError("Please enter a valid date.");
    } else if (selectedDate < minDateObj) {
      setDateError("Please select a date from today onwards.");
    } else if (selectedDate > maxDateObj) {
      setDateError("Please select a date within the next 6 months.");
    } else {
      setDateError("");
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "919924241719";
    const formattedDate = formatDate(formData.date);
    const message = `New PPF Service Booking:
    
      Name: ${formData.name}
      Email: ${formData.email}
      Phone: ${formData.phone}
      Car Model: ${formData.carModel}
      Preferred Date: ${formattedDate}
      Message: ${formData.message}`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dateError) {
      toast.error("Please correct the date error before submitting.");
      return;
    }
    handleWhatsAppClick();
    toast.success("Booking submitted successfully!");

    // Clear the form
    setFormData({
      name: "",
      email: "",
      phone: "",
      carModel: "",
      date: "",
      message: "",
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Best Paint-Protection-Film Services in Ahmedabad | Arok Enterprise
          Finish
        </title>
        <meta
          name="description"
          content="Discover the best paint-protection-film services in Ahmedabad with Arok Enterprise. Protect your vehicle's paint and maintain its value today!"
        />
        <meta
          property="og:title"
          content="Paint Protection Film | Arok Enterprise"
        />
        <meta
          property="og:description"
          content="Arok Enterprise offers top-notch paint protection film services to safeguard your car's paint from scratches and chips."
        />
        <meta
          property="og:url"
          content="https://www.arokenterprise.com/paint-protection-film"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Paint Protection Film | Arok Enterprise"
        />
        <meta
          name="twitter:description"
          content="Protect your vehicle's paint with Arok Enterprise's paint protection film services, designed to prevent damage and maintain value."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logo.png"
        />
        <link
          rel="canonical"
          href="https://www.arokenterprise.com/paint-protection-film"
        />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white font-sans">
        <Header />
        <Toaster position="top-center" reverseOrder={false} />
        <main>
          <header className="relative h-screen flex items-center justify-center">
            <motion.div
              initial={{ scale: 1.2, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 1.5 }}
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `url(${backgroundImage})`,
                filter: "brightness(0.5)",
              }}
            ></motion.div>
            <div className="container mx-auto px-4 relative z-10 text-center">
              <motion.h1
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.5, duration: 0.8 }}
                className="text-6xl font-bold mb-6 text-white"
              >
                Paint Protection Film
              </motion.h1>
              <motion.p
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.8, duration: 0.8 }}
                className="text-2xl mb-12 text-gray-300"
              >
                Ultimate Defense for Your Vehicle's Beauty
              </motion.p>
              <HashLink
                to="#ppfForm"
                smooth
                className="bg-red-600 hover:bg-red-700 text-white text-lg font-bold py-3 px-8 rounded-full transition duration-300"
              >
                Protect Your Car Now
              </HashLink>
            </div>
            <motion.div
              animate={{ y: [0, 10, 0] }}
              transition={{ repeat: Infinity, duration: 1.5 }}
              className="absolute bottom-10 left-1/2 transform -translate-x-1/2"
            >
              <ChevronDown size={36} color="white" />
            </motion.div>
          </header>

          <div className="container mx-auto px-4 py-20">
            <FadeInWhenVisible>
              <section className="mb-32">
                <h2 className="text-4xl font-bold mb-8 text-red-500 text-center">
                  What is Paint Protection Film?
                </h2>
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-gray-900 p-8 rounded-lg shadow-lg"
                >
                  <p className="text-gray-300 text-lg leading-relaxed">
                    Paint Protection Film (PPF) is a state-of-the-art, clear
                    urethane film that is precision-cut and professionally
                    applied to your vehicle's exterior. This nearly invisible
                    barrier acts as a shield against various forms of damage,
                    ensuring your car maintains its showroom shine for years to
                    come.
                  </p>
                </motion.div>
              </section>
            </FadeInWhenVisible>

            <FadeInWhenVisible>
              <section className="mb-40">
                <h2 className="text-6xl font-bold mb-16 text-center text-transparent bg-clip-text bg-gradient-to-b from-red-500 to-red-800">
                  Benefits of Paint Protection Film
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {benefits.map((benefit, index) => (
                    <WindowsHoverEffect
                      key={index}
                      className="bg-gradient-to-br from-gray-800 to-gray-900 p-10 rounded-sm shadow-lg transition-all duration-500 ease-in-out transform hover:shadow-2xl"
                    >
                      <benefit.icon className="text-red-500 mb-8 w-16 h-16" />
                      <p className="text-2xl font-semibold text-gray-200">
                        {benefit.text}
                      </p>
                    </WindowsHoverEffect>
                  ))}
                </div>
              </section>
            </FadeInWhenVisible>

            <FadeInWhenVisible>
              <section
                id="ppfForm"
                className="bg-gradient-to-b from-gray-800 to-black p-8 rounded-lg shadow-lg"
              >
                <h2 className="text-4xl font-bold mb-10 text-white text-center">
                  Book Your PPF Service
                </h2>
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="mb-4">
                      <label
                        htmlFor="name"
                        className="block text-lg font-semibold mb-2 text-gray-300"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-3 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="email"
                        className="block text-lg font-semibold mb-2 text-gray-300"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-3 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="phone"
                        className="block text-lg font-semibold mb-2 text-gray-300"
                      >
                        Phone
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-3 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="carModel"
                        className="block text-lg font-semibold mb-2 text-gray-300"
                      >
                        Car Model
                      </label>
                      <input
                        type="text"
                        id="carModel"
                        name="carModel"
                        value={formData.carModel}
                        onChange={handleInputChange}
                        required
                        className="w-full px-4 py-3 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="date"
                        className="block text-lg font-semibold mb-2 text-gray-300"
                      >
                        Preferred Date
                      </label>
                      <input
                        type="date"
                        id="date"
                        name="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        min={minDate}
                        max={maxDate}
                        required
                        className="w-full resize-none px-4 py-3 bg-gray-700 text-white rounded-md focus:outline-none appearance-none focus:ring-2 focus:ring-red-600"
                      />
                      {dateError && (
                        <p className="text-red-500 mt-2">{dateError}</p>
                      )}
                    </div>
                    <div className="mb-4 md:col-span-2">
                      <label
                        htmlFor="message"
                        className="block text-lg font-semibold mb-2 text-gray-300"
                      >
                        Message (Optional)
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        className="w-full h-32 px-4 py-3 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-red-600"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="bg-red-600 hover:bg-red-700 text-white text-lg font-bold py-3 px-8 rounded-full transition duration-300"
                    >
                      Submit Booking
                    </button>
                  </div>
                </form>
              </section>
            </FadeInWhenVisible>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default PaintProtectionFilmPage;
