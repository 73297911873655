import React, { useState, useEffect } from "react";
import { CarIcon } from "lucide-react";
import carImage from "../assets/car-grey.6d5c60303ba9b7dc5552.png";
import { GiSteeringWheel } from "react-icons/gi";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showCarAnimation, setShowCarAnimation] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    setShowCarAnimation(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      setShowCarAnimation(false);
    }, 3000);
  };

  return (
    <>
      <button
        className={`fixed bottom-4 right-4 rounded-full bg-gray-700 text-white p-4 shadow-md transition-transform duration-300 ${
          isVisible ? "scale-100" : "scale-0"
        }`}
        onClick={scrollToTop}
      >
        {!showCarAnimation && (
          <GiSteeringWheel className="w-6 h-6 hover:animate-[spin_0.5s_ease-in-out]" />
        )}
      </button>
      {showCarAnimation && (
        <div
          className={`fixed top-1/2 right-0 -translate-y-1/2 w-screen h-screen flex justify-end items-center z-50 animate-fasterCarAnimation`}
        >
          <img
            src={carImage}
            alt="car"
            className="w-full h-auto max-w-[200px]"
          />
        </div>
      )}
    </>
  );
};

export default BackToTopButton;
