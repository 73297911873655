import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import axios from "axios";
import { ChevronLeft, ShoppingCart, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useCart } from "../context/CartContext";
import { Toaster, toast } from "react-hot-toast";
import Footer from "../components/Footer";

const ProductCard = ({ product, onAddToCart }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
      className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 text-white rounded-lg shadow-lg p-4 m-4 w-full md:w-1/3 lg:w-1/4 border border-red-500 relative overflow-hidden cursor-pointer"
      onClick={() => navigate(`/product/${product._id}`)}
    >
      <div className="absolute top-0 right-0 w-16 h-16 bg-red-600 transform rotate-45 translate-x-8 -translate-y-8"></div>
      <div className="relative">
        <img
          src={product.images[0]}
          alt={product.name}
          className="w-full h-48 object-cover rounded-lg"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent"></div>
      </div>
      <h2 className="text-xl font-bold mt-4 text-red-500">{product.name}</h2>
      <p className="text-gray-300 mt-2 h-20 overflow-hidden">
        {product.description}
      </p>
      <div className="mt-4 flex items-center justify-between">
        <span className="text-2xl font-bold text-white">
          Rs. {product.price.toFixed(2)}
        </span>
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent the click event from bubbling up to the container
            onAddToCart(product);
          }}
          className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 flex items-center"
        >
          <ShoppingCart className="w-5 h-5 mr-2" />
          <span>Add to Cart</span>
        </button>
      </div>
    </motion.div>
  );
};

const CategoryItemsPage = () => {
  const navigate = useNavigate();
  const { categoryUrlName, subcategoryUrlName } = useParams();
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart, getCartItemCount } = useCart();
  const toastIdRef = useRef(null);

  const fetchCategoryAndProducts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [categoryResponse, productsResponse] = await Promise.all([
        axios.get(
          `${
            import.meta.env.VITE_SERVER_DOMAIN
          }/api/categories/${categoryUrlName}`
        ),
        axios.get(`${import.meta.env.VITE_SERVER_DOMAIN}/api/products`, {
          params: {
            category: categoryUrlName,
            subcategory: subcategoryUrlName,
          },
        }),
      ]);

      setCategory(categoryResponse.data);
      setSubcategory(
        categoryResponse.data.subcategories.find(
          (sub) => sub.urlName === subcategoryUrlName
        )
      );
      setProducts(productsResponse.data);
    } catch (error) {
      console.error("Error fetching category and products", error);
      setError("Failed to load category and products. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [categoryUrlName, subcategoryUrlName]);

  useEffect(() => {
    fetchCategoryAndProducts();
  }, [fetchCategoryAndProducts]);

  useEffect(() => {
    fetchCategoryAndProducts();

    // Cleanup function to dismiss any lingering toasts
    return () => {
      if (toastIdRef.current) {
        toast.dismiss(toastIdRef.current);
      }
    };
  }, [fetchCategoryAndProducts]);

  const handleAddToCart = (product) => {
    addToCart({ ...product, quantity: 1 });
    // Dismiss any existing toast before showing a new one
    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
    }

    toastIdRef.current = toast(
      (t) => (
        <div className="flex flex-col items-start bg-gray-800 text-white p-4 rounded-lg shadow-lg">
          <div className="flex items-center justify-between w-full mb-2">
            <span className="font-bold text-lg">Item Added to Cart</span>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="text-gray-400 hover:text-white transition-colors duration-200"
            >
              <X size={18} />
            </button>
          </div>
          <p className="mb-4">{product.name} has been added to your cart.</p>
          <div className="flex justify-between w-full">
            <button
              onClick={() => {
                navigate("/cart");
                toast.dismiss(t.id);
              }}
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
            >
              View Cart
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
            >
              Continue Shopping
            </button>
          </div>
        </div>
      ),
      {
        duration: 1500,
        style: {
          background: "transparent",
          boxShadow: "none",
          padding: 0,
        },
      }
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  if (!category || !subcategory) {
    return (
      <div className="text-gray-300 text-center mt-8">
        Category or subcategory not found
      </div>
    );
  }

  return (
    <>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            maxWidth: "400px",
            padding: "0",
          },
        }}
      />
      <Header cartItemCount={getCartItemCount()} />
      <section className="py-12 bg-gradient-to-b from-gray-900 to-black min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8">
          <div className="mb-8">
            <Link
              to={`/category/${categoryUrlName}`}
              className="text-red-500 hover:text-red-600 transition duration-300 flex items-center"
            >
              <ChevronLeft className="w-5 h-5 mr-2" />
              <span>Back to {category.name}</span>
            </Link>
          </div>
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="text-3xl font-bold text-red-500 mb-8"
          >
            {subcategory.name}
          </motion.h1>
          <p className="text-gray-300 mb-8">{subcategory.description}</p>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="flex flex-wrap -mx-4"
            >
              {products.map((product) => (
                <ProductCard
                  key={product._id}
                  product={product}
                  onAddToCart={handleAddToCart}
                />
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CategoryItemsPage;
