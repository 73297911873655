import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Header from "../components/Header";
import axios from "axios";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import Footer from "../components/Footer";

const SubcategoryCard = ({ category, subcategory }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    transition={{ duration: 0.3 }}
    className="bg-gradient-to-br from-gray-800 via-gray-700 to-gray-800 text-white rounded-lg shadow-lg p-6 m-4 w-full md:w-[calc(50%-2rem)] lg:w-[calc(33.333%-2rem)] border border-red-500 hover:shadow-xl transition-all duration-300"
  >
    <Link
      to={`/category/${category.urlName}/${subcategory.urlName}`}
      className="h-full flex flex-col"
    >
      <img
        src={subcategory.image}
        alt={subcategory.name}
        className="w-full h-48 object-cover rounded-lg mb-4"
      />
      <h2 className="text-2xl font-bold mb-2 text-red-500">
        {subcategory.name}
      </h2>
      <p className="text-gray-300 mb-4 flex-grow">{subcategory.description}</p>
      <div className="flex items-center text-red-500 hover:text-red-400 mt-auto">
        <span>View Products</span>
        <ChevronRight className="w-5 h-5 ml-2" />
      </div>
    </Link>
  </motion.div>
);

const SubcategoryPage = () => {
  const { categoryUrlName } = useParams();
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${
            import.meta.env.VITE_SERVER_DOMAIN
          }/api/categories/${categoryUrlName}`
        );
        setCategory(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching category", error);
        setError("Failed to load category. Please try again.");
        setLoading(false);
      }
    };

    fetchCategory();
  }, [categoryUrlName]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center mt-8 bg-gray-900 min-h-screen py-24">
        {error}
      </div>
    );
  }

  if (!category) {
    return (
      <div className="text-gray-300 text-center mt-8 bg-gray-900 min-h-screen py-24">
        Category not found
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-gray-900 to-black min-h-screen">
      <Header />
      <section className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mb-8">
            <Link
              to="/car-accessories"
              className="text-red-500 hover:text-red-600 transition duration-300 flex items-center"
            >
              <ChevronLeft className="w-5 h-5 mr-2" />
              <span>Back to Categories</span>
            </Link>
          </div>
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="text-3xl font-bold text-red-500 mb-8"
          >
            {category.name}
          </motion.h1>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="flex flex-wrap -mx-4 justify-center"
            >
              {category.subcategories.map((subcategory) => (
                <SubcategoryCard
                  key={subcategory._id}
                  category={category}
                  subcategory={subcategory}
                />
              ))}
            </motion.div>
          </AnimatePresence>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SubcategoryPage;
