// import React, { useRef, useEffect, useCallback } from "react";

// const WindowsHoverEffect = ({
//   children,
//   className = "",
//   style = {},
//   groupName = "default-group",
// }) => {
//   const elementRef = useRef(null);

//   useEffect(() => {
//     const element = elementRef.current;
//     if (element) {
//       element.setAttribute("data-windows-hover-group", groupName);
//     }
//     return () => {
//       if (element) {
//         element.removeAttribute("data-windows-hover-group");
//       }
//     };
//   }, [groupName]);

//   const applyEffect = useCallback((element, x, y, intensity = 1) => {
//     const rect = element.getBoundingClientRect();
//     const localX = x - rect.left;
//     const localY = y - rect.top;

//     // Create a new div for the glow effect
//     let glowElement = element.querySelector(".glow-effect");
//     if (!glowElement) {
//       glowElement = document.createElement("div");
//       glowElement.className = "glow-effect";
//       glowElement.style.position = "absolute";
//       glowElement.style.top = "0";
//       glowElement.style.left = "0";
//       glowElement.style.right = "0";
//       glowElement.style.bottom = "0";
//       glowElement.style.pointerEvents = "none";
//       element.appendChild(glowElement);
//     }

//     glowElement.style.background = `radial-gradient(circle at ${localX}px ${localY}px, rgba(255,255,255,${
//       0.2 * intensity
//     }), transparent)`;
//     element.style.borderImage = `radial-gradient(20% 75% at ${localX}px ${localY}px, rgba(255,255,255,${
//       0.7 * intensity
//     }), rgba(255,255,255,${0.1 * intensity})) 1 / 1px / 0px stretch`;
//   }, []);

//   const handleMouseMove = useCallback(
//     (e) => {
//       const currentElement = elementRef.current;
//       if (!currentElement) return;

//       const groupElements = document.querySelectorAll(
//         `[data-windows-hover-group="${groupName}"]`
//       );

//       groupElements.forEach((el) => {
//         if (el === currentElement) {
//           applyEffect(el, e.clientX, e.clientY, 1);
//         } else {
//           const rect = el.getBoundingClientRect();
//           const distance = Math.hypot(
//             e.clientX - (rect.left + rect.width / 2),
//             e.clientY - (rect.top + rect.height / 2)
//           );
//           const maxDistance =
//             Math.max(
//               document.documentElement.clientWidth,
//               document.documentElement.clientHeight
//             ) / 2;
//           const intensity = Math.max(0, 1 - distance / maxDistance);
//           applyEffect(el, e.clientX, e.clientY, intensity * 0.3);
//         }
//       });
//     },
//     [groupName, applyEffect]
//   );

//   const handleMouseLeave = useCallback(() => {
//     const groupElements = document.querySelectorAll(
//       `[data-windows-hover-group="${groupName}"]`
//     );
//     groupElements.forEach((el) => {
//       const glowElement = el.querySelector(".glow-effect");
//       if (glowElement) {
//         glowElement.style.background = "";
//       }
//       el.style.borderImage = "";
//     });
//   }, [groupName]);

//   return (
//     <div
//       ref={elementRef}
//       className={`relative overflow-hidden ${className}`}
//       style={{
//         transition: "border-image 0.3s",
//         ...style,
//       }}
//       onMouseMove={handleMouseMove}
//       onMouseLeave={handleMouseLeave}
//     >
//       {children}
//     </div>
//   );
// };

// export default WindowsHoverEffect;

import React, { useRef, useEffect, useCallback } from "react";

const WindowsHoverEffect = ({
  children,
  className = "",
  style = {},
  groupName = "default-group",
  colorMode = "white", // Add this prop to switch between black and white
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.setAttribute("data-windows-hover-group", groupName);
    }
    return () => {
      if (element) {
        element.removeAttribute("data-windows-hover-group");
      }
    };
  }, [groupName]);

  const applyEffect = useCallback(
    (element, x, y, intensity = 1) => {
      const rect = element.getBoundingClientRect();
      const localX = x - rect.left;
      const localY = y - rect.top;

      let glowElement = element.querySelector(".glow-effect");
      if (!glowElement) {
        glowElement = document.createElement("div");
        glowElement.className = "glow-effect";
        glowElement.style.position = "absolute";
        glowElement.style.top = "0";
        glowElement.style.left = "0";
        glowElement.style.right = "0";
        glowElement.style.bottom = "0";
        glowElement.style.pointerEvents = "none";
        element.appendChild(glowElement);
      }

      // Use the colorMode prop to determine the color
      const color = colorMode === "black" ? "0,0,0" : "255,255,255";

      glowElement.style.background = `radial-gradient(circle at ${localX}px ${localY}px, rgba(${color},${
        0.2 * intensity
      }), transparent)`;
      element.style.borderImage = `radial-gradient(20% 75% at ${localX}px ${localY}px, rgba(${color},${
        0.7 * intensity
      }), rgba(${color},${0.1 * intensity})) 1 / 1px / 0px stretch`;
    },
    [colorMode]
  ); // Add colorMode to the dependency array

  const handleMouseMove = useCallback(
    (e) => {
      const currentElement = elementRef.current;
      if (!currentElement) return;

      const groupElements = document.querySelectorAll(
        `[data-windows-hover-group="${groupName}"]`
      );

      groupElements.forEach((el) => {
        if (el === currentElement) {
          applyEffect(el, e.clientX, e.clientY, 1);
        } else {
          const rect = el.getBoundingClientRect();
          const distance = Math.hypot(
            e.clientX - (rect.left + rect.width / 2),
            e.clientY - (rect.top + rect.height / 2)
          );
          const maxDistance =
            Math.max(
              document.documentElement.clientWidth,
              document.documentElement.clientHeight
            ) / 2;
          const intensity = Math.max(0, 1 - distance / maxDistance);
          applyEffect(el, e.clientX, e.clientY, intensity * 0.3);
        }
      });
    },
    [groupName, applyEffect]
  );

  const handleMouseLeave = useCallback(() => {
    const groupElements = document.querySelectorAll(
      `[data-windows-hover-group="${groupName}"]`
    );
    groupElements.forEach((el) => {
      const glowElement = el.querySelector(".glow-effect");
      if (glowElement) {
        glowElement.style.background = "";
      }
      el.style.borderImage = "";
    });
  }, [groupName]);

  return (
    <div
      ref={elementRef}
      className={`relative overflow-hidden ${className}`}
      style={{
        transition: "border-image 0.3s",
        ...style,
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default WindowsHoverEffect;
