import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  MapPin,
  Phone,
  Mail,
  Clock,
  Car,
  Wrench,
  Star,
  Facebook,
  Instagram,
} from "lucide-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const Card = ({ children, className }) => (
  <motion.div
    className={`bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden ${className}`}
    whileHover={{ y: -5 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    {children}
  </motion.div>
);

const CardHeader = ({ children }) => (
  <div className="p-5 bg-gray-800 border-b border-gray-700">{children}</div>
);

const CardContent = ({ children }) => <div className="p-5">{children}</div>;

const Input = ({ className, ...props }) => (
  <input
    className={`w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-red-500 focus:outline-none focus:border-transparent transition-all duration-300 ${className}`}
    {...props}
  />
);

const Textarea = ({ className, ...props }) => (
  <textarea
    className={`w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-red-500 focus:outline-none focus:border-transparent transition-all duration-300 ${className}`}
    {...props}
  />
);

const Button = ({ children, className, ...props }) => (
  <motion.button
    className={`w-full p-3 bg-red-600 hover:bg-red-700 text-white focus:outline-none rounded-md shadow-md ${className}`}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    {...props}
  >
    {children}
  </motion.button>
);

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const message = `
Name: ${formData.name}
Email: ${formData.email}
Phone: ${formData.phone}
Subject: ${formData.subject}
Message: ${formData.message}
`;

    const whatsappUrl = `https://wa.me/919924241719?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");

    // Clear the form after submission
    setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <>
      <Helmet>
        <title>
          Contact AROK Enterprises, Ahmedabad | We’re Here to Assist You
        </title>
        <meta
          name="description"
          content="Have questions or need support? Contact AROK Enterprises today. Our team is ready to assist with your car accessories, services, and consultancy needs."
        />
        <meta property="og:title" content="Contact Arok Enterprise" />
        <meta
          property="og:description"
          content="Reach out to Arok Enterprise for all your automotive needs. We're here to help with any queries or services you require."
        />
        <meta
          property="og:url"
          content="https://www.arokenterprise.com/contact-us"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Arok Enterprise" />
        <meta
          name="twitter:description"
          content="Get in touch with Arok Enterprise for professional automotive services and expert advice."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logopng"
        />
        <link
          rel="canonical"
          href="https://www.arokenterprise.com/contact-us"
        />
      </Helmet>
      <Header />
      <div className="min-h-screen bg-gray-950 text-gray-200 pt-20">
        <div className="container mx-auto px-4 py-8">
          <motion.h1
            className="text-4xl md:text-5xl font-bold mb-8 text-center text-red-500"
            variants={fadeInUp}
            initial="initial"
            animate="animate"
          >
            Contact Us
          </motion.h1>

          <div className="grid md:grid-cols-2 gap-8">
            <motion.div
              variants={fadeInUp}
              initial="initial"
              animate="animate"
              className="space-y-8"
            >
              <Card>
                <CardHeader>
                  <h2 className="text-2xl font-semibold text-white">
                    Contact Information
                  </h2>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-4 mb-4">
                    <li className="flex items-center">
                      <MapPin className="w-5 h-5 mr-3 text-red-400" />
                      Shop No - 7 & 8, Riddhi siddhi Avenue, opp. Vithlesh
                      <br />
                      Indian oil petrol pump, beside Frndzz Tea Point, Nana
                      Chiloda, Ahmedabad, Gujarat 382330
                    </li>
                    <li className="flex items-center">
                      <Phone className="w-5 h-5 mr-3 text-red-400" />
                      <span>+91 9924241719</span>
                    </li>
                    <li className="flex items-center">
                      <Mail className="w-5 h-5 mr-3 text-red-400" />
                      <span>arokenterprise@gmail.com</span>
                    </li>
                    {/* <li className="flex items-center">
                      <Clock className="w-5 h-5 mr-3 text-red-400" />
                      <span>Mon-Fri 9AM-6PM, Sat 10AM-4PM</span>
                    </li> */}
                  </ul>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <h2 className="text-2xl font-semibold text-white">
                    Our Services
                  </h2>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-3">
                    <li className="flex items-center">
                      <Car className="w-5 h-5 mr-3 text-red-400" />
                      <span>Custom Car Accessories</span>
                    </li>
                    <li className="flex items-center">
                      <Wrench className="w-5 h-5 mr-3 text-red-400" />
                      <span>Professional Installation</span>
                    </li>
                    <li className="flex items-center">
                      <Star className="w-5 h-5 mr-3 text-red-400" />
                      <span>Performance Upgrades</span>
                    </li>
                  </ul>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <h2 className="text-2xl font-semibold text-white">
                    Connect With Us
                  </h2>
                </CardHeader>
                <CardContent>
                  <div className="flex justify-center space-x-8 mb-4">
                    <a
                      href="https://www.facebook.com/people/AROK-Enterprise/61561944091103/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-red-400 transition-colors"
                    >
                      <Facebook size={32} />
                    </a>
                    <a
                      href="https://www.instagram.com/arokenterprise09/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-red-400 transition-colors"
                    >
                      <Instagram size={32} />
                    </a>
                    <a
                      href="https://wa.me/919924241719"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-red-400 transition-colors"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M3 21l1.65-3.8a9 9 0 1 1 3.4 2.9L3 21" />
                        <path d="M9 10a.5.5 0 0 0 1 0V9a.5.5 0 0 0-1 0v1a5 5 0 0 0 5 5h1a.5.5 0 0 0 0-1h-1a.5.5 0 0 0 0 1" />
                      </svg>
                    </a>
                  </div>
                  <p className="text-center text-gray-400">
                    Follow us on social media for the latest updates and offers!
                  </p>
                </CardContent>
              </Card>
            </motion.div>

            <motion.div variants={fadeInUp} initial="initial" animate="animate">
              <Card>
                <CardHeader>
                  <h2 className="text-2xl font-semibold text-white">
                    Get in Touch
                  </h2>
                </CardHeader>
                <CardContent>
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium mb-1"
                      >
                        Name
                      </label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium mb-1"
                      >
                        Email
                      </label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium mb-1"
                      >
                        Phone
                      </label>
                      <Input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="subject"
                        className="block text-sm font-medium mb-1"
                      >
                        Subject
                      </label>
                      <Input
                        type="text"
                        id="subject"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="message"
                        className="block text-sm font-medium mb-1"
                      >
                        Message
                      </label>
                      <Textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                        rows={5}
                      />
                    </div>
                    <Button type="submit">Send Message</Button>
                  </form>
                </CardContent>
              </Card>
            </motion.div>
          </div>
          <motion.div
            className="mt-12"
            variants={fadeInUp}
            initial="initial"
            animate="animate"
          >
            <Card>
              <CardHeader>
                <h2 className="text-2xl font-semibold text-white">Find Us</h2>
              </CardHeader>
              <CardContent>
                <div className="aspect-w-16 aspect-h-9 h-[500px]">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.9372672710315!2d72.66471707509423!3d23.099392179120063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e81f124a4291b%3A0x794234c7c4256f04!2sArok%20Enterprise!5e0!3m2!1sen!2sin!4v1722577353172!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Arok Enterprise Location"
                    className="rounded-lg"
                  ></iframe>
                </div>
              </CardContent>
            </Card>
          </motion.div>

          <motion.div
            className="mt-12 text-center"
            variants={fadeInUp}
            initial="initial"
            animate="animate"
          >
            <h2 className="text-3xl font-semibold text-red-600 mb-4">
              Why Choose Arok Enterprise?
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Arok Enterprise is your premier destination for top-quality car
              accessories and expert installation. With a seasoned team of
              professionals, we are committed to enhancing your vehicle's
              performance, style, and functionality. Trust Arok Enterprise to
              provide exceptional products and services tailored to meet your
              automotive needs.
            </p>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
