import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { ChevronRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import car2 from "../assets/headlights-blinking-car.mp4";
import { Helmet } from "react-helmet-async";

const CategoryCard = ({ category }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
    transition={{ duration: 0.3 }}
    className="bg-gradient-to-br from-gray-800 via-gray-700 to-gray-800 text-white rounded-lg shadow-lg p-6 m-4 w-full md:w-[calc(50%-2rem)] lg:w-[calc(33.333%-2rem)] border border-red-500 hover:shadow-xl transition-all duration-300"
  >
    <Link to={`/category/${category.urlName}`} className="h-full flex flex-col">
      <div className="relative mb-4">
        <img
          src={category.image}
          alt={category.name}
          className="w-full h-48 object-cover rounded-lg"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent"></div>
        <div className="absolute bottom-2 left-2 bg-red-600 text-white px-3 py-1 text-sm font-bold uppercase tracking-wider rounded">
          {category.name}
        </div>
      </div>
      <h2 className="text-2xl font-bold mb-2 text-red-500">{category.name}</h2>
      <p className="text-gray-300 mb-4 flex-grow">{category.description}</p>
      <div className="flex items-center text-red-500 hover:text-red-400 mt-auto">
        <span>View Subcategories</span>
        <ChevronRight className="w-5 h-5 ml-2" />
      </div>
    </Link>
  </motion.div>
);

const CarAccessoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${import.meta.env.VITE_SERVER_DOMAIN}/api/categories`
        );
        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories", error);
        setError("Failed to load categories. Please try again.");
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleAddToCart = (product) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item._id === product._id);
      if (existingItem) {
        return prevCart.map((item) =>
          item._id === product._id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prevCart, { ...product, quantity: 1 }];
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-center mt-8 bg-gray-900 min-h-screen py-24">
        {error}
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Top Car Accessories Shop in Ahmedabad - Nana Chiloda | Arok Enterprise
        </title>
        <meta
          name="description"
          content="Discover the best car accessories at our top shop in Ahmedabad, Nana Chiloda. Upgrade your ride today with quality products and amazing deals!"
        />
        <meta property="og:title" content="Car Accessories | Arok Enterprise" />
        <meta
          property="og:description"
          content="Discover a wide range of car accessories at Arok Enterprise to enhance your vehicle's style, comfort, and functionality."
        />
        <meta
          property="og:url"
          content="https://www.arokenterprise.com/car-accessories"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Car Accessories | Arok Enterprise"
        />
        <meta
          name="twitter:description"
          content="Upgrade your car with premium accessories from Arok Enterprise. Find everything you need to personalize your vehicle."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logo.png"
        />
        <link
          rel="canonical"
          href="https://www.arokenterprise.com/car-accessories"
        />
      </Helmet>
      <div className="bg-gradient-to-t from-black to-gray-900 from-10% to-90% min-h-screen">
        <Header
          cartItemCount={cart.reduce((total, item) => total + item.quantity, 0)}
        />
        <section className="relative text-white h-[100dvh]">
          <video
            src={car2}
            type="video/mp4"
            autoPlay
            loop
            muted
            playsInline
            preload="auto"
            onContextMenu={(e) => e.preventDefault()}
            className="absolute inset-0 w-full h-full object-cover opacity-85"
          />
          <div className="relative z-10 flex flex-col items-center justify-center h-full max-w-7xl mx-auto p-6 text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-16 orbitron">
              Upgrade Your Ride with Premium Car Accessories
            </h1>
            <p className="text-sm md:text-xl mb-8">
              Discover a wide range of car accessories to enhance your driving
              experience. From interior upgrades to exterior enhancements, we
              have everything you need to make your car truly yours.
            </p>
            <HashLink
              smooth
              to="/car-accessories#shop"
              className="bg-red-600 hover:bg-red-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 flex items-center"
            >
              <span>Shop Now</span>
              <ChevronRight className="w-5 h-5 ml-2" />
            </HashLink>
          </div>
        </section>
        <section id="shop" className="py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.h2
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="text-3xl font-bold text-red-500 mb-8 text-center"
            >
              Explore Our Accessories Categories
            </motion.h2>
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="flex flex-wrap -mx-4 justify-start"
              >
                {categories.map((category) => (
                  <CategoryCard key={category._id} category={category} />
                ))}
              </motion.div>
            </AnimatePresence>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default CarAccessoriesPage;
