import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const BlogsPage = () => {
  return (
    <>
      <Helmet>
        <title>
          AROK Enterprises Blog | Tips, Trends, and Insights on Car Accessories
          & Care
        </title>
        <meta
          name="description"
          content="Stay updated with the latest trends, tips, and insights on car accessories, maintenance, and auto services. Explore AROK Enterprises’ blog for expert advice and inspiration."
        />
        <meta property="og:title" content="Arok Enterprise Blogs" />
        <meta
          property="og:description"
          content="Stay updated with the latest automotive trends, tips, and news through Arok Enterprise's insightful blogs."
        />
        <meta
          property="og:url"
          content="https://www.arokenterprise.com/blogs"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Arok Enterprise Blogs" />
        <meta
          name="twitter:description"
          content="Explore Arok Enterprise's blogs for expert advice, tips, and the latest news in the automotive industry."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logo.png"
        />
        <link rel="canonical" href="https://www.arokenterprise.com/blogs" />
      </Helmet>
      <Header />
      <div className="h-[100dvh] pt-20">BlogsPage</div>
      <Footer />
    </>
  );
};

export default BlogsPage;
