import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Car,
  ShoppingCart,
  Wrench,
  Check,
  Phone,
  Star,
  ArrowRight,
  ArrowLeft,
} from "lucide-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import carVideo from "../assets/car-video.mp4";
import car1 from "../assets/car1.webp";
import car2 from "../assets/car2.webp";
import car3 from "../assets/car3.webp";
import car4 from "../assets/car4.webp";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  const [scrollY, setScrollY] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [currentGalleryImage, setCurrentGalleryImage] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const testimonialInterval = setInterval(() => {
      setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    }, 3000);
    return () => clearInterval(testimonialInterval);
  }, []);

  const testimonials = [
    {
      name: "John D.",
      text: "Arok's products transformed my car's interior. Top-notch quality!",
    },
    {
      name: "Sarah M.",
      text: "Excellent customer service and fast shipping. Will shop here again!",
    },
    {
      name: "Mike R.",
      text: "The expert installation service saved me time and hassle. Highly recommended!",
    },
  ];

  const galleryImages = [car1, car2, car3, car4];

  const ParallaxSection = ({ children }) => (
    <motion.div style={{ y: scrollY * 0.5, opacity: 1 - scrollY * 0.002 }}>
      {children}
    </motion.div>
  );

  return (
    <>
      <Helmet>
        <title>Top Car Accessories shop in Ahmedabad | Arok Enterprise</title>
        <meta
          name="description"
          content="Discover quality car accessories in Ahmedabad at Arok Enterprise. Upgrade your  vehicle with our premium products designed for style and performance."
        />
        <meta
          property="og:title"
          content="Arok Enterprise | Your One-Stop Automotive Solution"
        />
        <meta
          property="og:description"
          content="Arok Enterprise offers a wide range of automotive services, including car accessories, ceramic coating, paint protection film, and more."
        />
        <meta property="og:url" content="https://www.arokenterprise.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Arok Enterprise | Your One-Stop Automotive Solution"
        />
        <meta
          name="twitter:description"
          content="Explore top-notch car services and products at Arok Enterprise, including accessories, ceramic coating, paint protection, and more."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logo.png"
        />
        <link rel="canonical" href="https://www.arokenterprise.com/" />
      </Helmet>
      <div className="bg-gray-900 text-white">
        <Header />

        <HeroSection ParallaxSection={ParallaxSection} />
        <FeaturesSection />
        <ImageGallery
          images={galleryImages}
          currentImage={currentGalleryImage}
          setCurrentImage={setCurrentGalleryImage}
        />
        <TestimonialsSection
          testimonials={testimonials}
          currentTestimonial={currentTestimonial}
        />
        <ProductCategories />
        <ContactCTA />
        <WhyChooseUs />
        <CallToActionBanner />

        <Footer />
        <WhatsAppButton />
      </div>
    </>
  );
};

const WhatsAppButton = () => (
  <motion.a
    href="https://wa.me/919924241719"
    target="_blank"
    rel="noopener noreferrer"
    className="fixed left-4 bottom-4 z-50 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-colors duration-300"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    <FaWhatsapp size={24} />
  </motion.a>
);

const HeroSection = ({ ParallaxSection }) => (
  <section className="relative h-screen flex items-center justify-center overflow-hidden">
    <video
      src={carVideo}
      autoPlay
      loop
      muted
      className="absolute top-0 left-0 w-full h-full object-cover"
    />
  </section>
);

const FeaturesSection = () => {
  const features = [
    {
      icon: Car,
      title: "Wide Selection",
      description: "From luxurious interiors to high-performance parts",
    },
    {
      icon: ShoppingCart,
      title: "Easy Shopping",
      description: "User-friendly online store with secure checkout",
    },
    {
      icon: Wrench,
      title: "Expert Installation",
      description: "Professional fitting service available",
    },
  ];

  return (
    <section className="py-20 px-4 bg-gradient-to-b from-gray-900 to-black">
      <div className="max-w-6xl mx-auto">
        <motion.div
          transition={{ duration: 1 }}
          className="relative z-10 text-center"
        >
          <h1 className="text-6xl font-bold mb-4 text-red-600">
            Arok Enterprise
          </h1>
          <p className="text-3xl mb-8 text-gray-300">
            Elevate Your Ride with Premium Accessories
          </p>
          <Link to="/car-accessories">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-red-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300"
            >
              Shop Now
            </motion.button>
          </Link>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="text-center bg-gray-800 p-8 rounded-lg shadow-lg hover:shadow-red-600/30 transition-all duration-300 transform hover:-translate-y-1"
            >
              <feature.icon className="w-16 h-16 mx-auto mb-6 text-red-500" />
              <h3 className="text-2xl font-semibold mb-4 text-white">
                {feature.title}
              </h3>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const ImageGallery = ({ images, currentImage, setCurrentImage }) => (
  <section className="py-20 px-4 bg-gradient-to-t from-black via-gray-900 to-black">
    <div className="max-w-6xl mx-auto">
      <h2 className="text-4xl font-bold mb-12 text-center text-red-600">
        Our Work
      </h2>
      <div className="relative">
        <motion.img
          key={currentImage}
          src={images[currentImage]}
          alt={`Gallery Image ${currentImage + 1}`}
          className="w-full h-[75vh] object-cover rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        />
        <motion.button
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black/35 hover:bg-gradient-to-tl from-red-500 to-black p-3 rounded-full"
          onClick={() =>
            setCurrentImage(
              (prev) => (prev - 1 + images.length) % images.length
            )
          }
        >
          <ArrowLeft className="w-6 h-6" />
        </motion.button>
        <motion.button
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black/35 hover:bg-gradient-to-tr from-red-500 to-black  p-3 rounded-full"
          onClick={() => setCurrentImage((prev) => (prev + 1) % images.length)}
        >
          <ArrowRight className="w-6 h-6" />
        </motion.button>
      </div>
    </div>
  </section>
);

const TestimonialsSection = ({ testimonials, currentTestimonial }) => (
  <section className="py-20 px-4 bg-gradient-to-b from-black to-gray-900">
    <div className="max-w-4xl mx-auto">
      <h2 className="text-4xl font-bold mb-12 text-center text-red-600">
        What Our Customers Say
      </h2>
      <div className="relative h-40">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentTestimonial}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="absolute inset-0 flex flex-col items-center justify-center text-center"
          >
            <p className="text-xl mb-4 italic">
              "{testimonials[currentTestimonial].text}"
            </p>
            <p className="font-semibold text-red-600">
              - {testimonials[currentTestimonial].name}
            </p>
            <div className="flex mt-2">
              {[...Array(5)].map((_, i) => (
                <Star
                  key={i}
                  className="w-5 h-5 text-yellow-500 fill-current"
                />
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  </section>
);

const ContactCTA = () => (
  <section className="py-16 px-4 bg-gradient-to-r from-red-600 to-red-800">
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-3xl font-bold mb-4">Need Help Choosing?</h2>
      <p className="text-xl mb-8">
        Our experts are here to assist you in finding the perfect accessories
        for your vehicle.
      </p>
      <motion.a
        href="tel:+919924241719"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="inline-flex items-center bg-white text-red-600 px-6 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300"
      >
        <Phone className="w-6 h-6 mr-2" />
        Call Us Now
      </motion.a>
    </div>
  </section>
);

const ProductCategories = () => {
  const categories = [
    {
      name: "Interior",
      icon: "🛋️",
      description: "Upgrade your car's interior with our premium accessories",
    },
    {
      name: "Exterior",
      icon: "🚗",
      description: "Enhance your vehicle's look with our stylish exterior mods",
    },
    {
      name: "Performance",
      icon: "🏎️",
      description: "Boost your car's performance with our high-quality parts",
    },
    {
      name: "Electronics",
      icon: "🎧",
      description: "Stay connected with our cutting-edge car electronics",
    },
    {
      name: "Wheels & Tires",
      icon: "🛞",
      description: "Roll in style with our selection of wheels and tires",
    },
    {
      name: "Maintenance",
      icon: "🔧",
      description: "Keep your car in top shape with our maintenance products",
    },
  ];

  return (
    <section className="py-20 px-4 bg-gray-900">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-12 text-center text-red-600">
          Explore Our Categories
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {categories.map((category, index) => (
            <motion.div
              key={index}
              whileHover={{ scale: 1.05, backgroundColor: "#4B5563" }}
              className="bg-gray-800 rounded-lg p-6 text-center cursor-pointer transition-colors duration-300"
            >
              <span className="text-4xl mb-4 block">{category.icon}</span>
              <h3 className="text-2xl font-semibold mb-2 text-red-600">
                {category.name}
              </h3>
              <p className="text-gray-300">{category.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const WhyChooseUs = () => {
  const reasons = [
    {
      title: "Quality Assurance",
      description:
        "All our products undergo rigorous quality checks to ensure durability and performance.",
    },
    {
      title: "Expert Support",
      description:
        "Our team of automotive enthusiasts is always ready to assist you with expert advice.",
    },
    {
      title: "Fast Shipping",
      description:
        "We offer quick and reliable shipping to get your products to you as soon as possible.",
    },
    {
      title: "Easy Returns",
      description: "Hassle-free 30-day return policy for your peace of mind.",
    },
  ];

  return (
    <section className="py-20 px-4 bg-black">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-12 text-center text-red-600">
          Why Choose Arok Enterprise
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {reasons.map((reason, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex items-start space-x-4 bg-gray-900 p-6 rounded-lg"
            >
              <div className="flex-shrink-0">
                <div className="w-12 h-12 rounded-full bg-red-600 flex items-center justify-center">
                  <Check className="w-6 h-6 text-white" />
                </div>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2 text-red-600">
                  {reason.title}
                </h3>
                <p className="text-gray-300">{reason.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

const CallToActionBanner = () => (
  <section className="py-12 px-4 bg-gradient-to-r from-red-600 to-red-800">
    <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between">
      <div className="mb-6 md:mb-0">
        <h2 className="text-3xl font-bold">Ready to Upgrade Your Ride?</h2>
        <p className="text-xl mt-2">
          Explore our collection and transform your vehicle today!
        </p>
      </div>
      <Link to="/car-accessories">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-white text-red-600 px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300"
        >
          Shop Now
        </motion.button>
      </Link>
    </div>
  </section>
);

export default HomePage;
