import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/logo2.png";
import BackToTopButton from "./BackToTop";

const Footer = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const navLinks = [
    { title: "Home", path: "/" },
    { title: "Car Accessories", path: "/car-accessories" },
    { title: "Ceramic Coating", path: "/ceramic-coating" },
    { title: "Paint Protection Film", path: "/paint-protection-film" },
    { title: "Auto Consultant", path: "/auto-consultant" },
    { title: "Car Loan And Insurance", path: "/car-loan-and-insurance" },
    { title: "Contact Us", path: "/contact-us" },
    { title: "About Us", path: "/about-us" },
  ];

  return (
    <footer className="bg-gradient-to-t from-gray-900 to-black text-white ">
      <div className="max-w-7xl mx-auto p-8">
        <motion.div
          className="grid grid-cols-1 md:grid-cols-3 gap-12"
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
        >
          <div className="space-y-6">
            <div className="flex items-center">
              <img
                src={logo}
                alt="Logo"
                className="w-16 h-16 mr-4 bg-white rounded-md"
              />
              <h2 className="text-2xl font-bold text-red-600">
                AROK Enterprises
              </h2>
            </div>
            <p className="text-sm text-gray-300 leading-relaxed text-justify">
              Arok Enterprise is your premier destination for top-quality car
              accessories and expert installation. With a seasoned team of
              professionals, we are committed to enhancing your vehicle's
              performance, style, and functionality. Trust Arok Enterprise to
              provide exceptional products and services tailored to meet your
              automotive needs.
            </p>
          </div>

          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-red-600">Quick Links</h3>
            <nav className="grid grid-cols-2 gap-4">
              {navLinks.map((link) => (
                <Link
                  key={link.title}
                  to={link.path}
                  className="text-sm text-gray-300 hover:text-red-400 transition-colors duration-300"
                >
                  {link.title}
                </Link>
              ))}
            </nav>
          </div>

          <div className="space-y-6">
            <h3 className="text-xl font-semibold text-red-600">Contact Us</h3>
            <div className="space-y-4 text-sm text-gray-300">
              <div className="flex items-start">
                <LocationIcon className="w-5 h-5 mr-3 text-red-500 flex-shrink-0 mt-1" />
                <address className="not-italic">
                  Shop No - 7 & 8, Riddhi siddhi Avenue, opp. Vithlesh Indian
                  oil petrol pump, beside Frndzz Tea Point, Nana Chiloda,
                  Ahmedabad, Gujarat 382330
                </address>
              </div>
              <div className="flex items-center">
                <EmailIcon className="w-5 h-5 mr-3 text-red-500 flex-shrink-0" />
                <a
                  href="mailto:arokenterprise@gmail.com"
                  className="hover:text-red-400 transition-colors duration-300"
                >
                  arokenterprise@gmail.com
                </a>
              </div>
              <div className="flex items-center">
                <PhoneIcon className="w-5 h-5 mr-3 text-red-500 flex-shrink-0" />
                <a
                  href="tel:+919924241719"
                  className="hover:text-red-400 transition-colors duration-300"
                >
                  +91 9924241719
                </a>
              </div>
            </div>
            <div className="flex space-x-4">
              <SocialLink
                href="https://www.facebook.com/people/AROK-Enterprise/61561944091103/"
                icon={<FacebookIcon />}
                label="Facebook"
              />
              <SocialLink
                href="https://www.instagram.com/arokenterprise09/"
                icon={<InstagramIcon />}
                label="Instagram"
              />
              <SocialLink
                href="https://wa.me/919924241719"
                icon={<WhatsAppIcon />}
                label="WhatsApp"
              />
            </div>
          </div>
        </motion.div>
      </div>
      <div className=" py-4">
        <div className="max-w-7xl mx-auto px-8 text-center">
          <p className="text-xs text-gray-500">
            &copy; {new Date().getFullYear()} Mannix Infotech Solutions,
            Ahmedabad. All rights reserved.
          </p>
        </div>
      </div>
      <BackToTopButton />
    </footer>
  );
};

const SocialLink = ({ href, icon, label }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    title={label}
    className="bg-red-600 p-2 rounded-full hover:bg-red-700 transition-colors duration-300"
  >
    {icon}
  </a>
);

const LocationIcon = (props) => (
  <svg
    {...props}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
    />
  </svg>
);

const EmailIcon = (props) => (
  <svg
    {...props}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    />
  </svg>
);

const PhoneIcon = (props) => (
  <svg
    {...props}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
    />
  </svg>
);

const FacebookIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.18182 10.3333C5.20406 10.3333 5 10.5252 5 11.4444V13.1111C5 14.0304 5.20406 14.2222 6.18182 14.2222H8.54545V20.8889C8.54545 21.8081 8.74951 22 9.72727 22H12.0909C13.0687 22 13.2727 21.8081 13.2727 20.8889V14.2222H15.9267C16.6683 14.2222 16.8594 14.0867 17.0631 13.4164L17.5696 11.7497C17.9185 10.6014 17.7035 10.3333 16.4332 10.3333H13.2727V7.55556C13.2727 6.94191 13.8018 6.44444 14.4545 6.44444H17.8182C18.7959 6.44444 19 6.25259 19 5.33333V3.11111C19 2.19185 18.7959 2 17.8182 2H14.4545C11.191 2 8.54545 4.48731 8.54545 7.55556V10.3333H6.18182Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

const InstagramIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      d="M2.5 12C2.5 7.52166 2.5 5.28249 3.89124 3.89124C5.28249 2.5 7.52166 2.5 12 2.5C16.4783 2.5 18.7175 2.5 20.1088 3.89124C21.5 5.28249 21.5 7.52166 21.5 12C21.5 16.4783 21.5 18.7175 20.1088 20.1088C18.7175 21.5 16.4783 21.5 12 21.5C7.52166 21.5 5.28249 21.5 3.89124 20.1088C2.5 18.7175 2.5 16.4783 2.5 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51472 16.5 7.5 14.4853 7.5 12C7.5 9.51472 9.51472 7.5 12 7.5C14.4853 7.5 16.5 9.51472 16.5 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M17.5078 6.5L17.4988 6.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const WhatsAppIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.3789 2.27907 14.6926 2.78382 15.8877C3.06278 16.5481 3.20226 16.8784 3.21953 17.128C3.2368 17.3776 3.16334 17.6521 3.01642 18.2012L2 22L5.79877 20.9836C6.34788 20.8367 6.62244 20.7632 6.87202 20.7805C7.12161 20.7977 7.45185 20.9372 8.11235 21.2162C9.30745 21.7209 10.6211 22 12 22Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M8.58815 12.3773L9.45909 11.2956C9.82616 10.8397 10.2799 10.4153 10.3155 9.80826C10.3244 9.65494 10.2166 8.96657 10.0008 7.58986C9.91601 7.04881 9.41086 7 8.97332 7C8.40314 7 8.11805 7 7.83495 7.12931C7.47714 7.29275 7.10979 7.75231 7.02917 8.13733C6.96539 8.44196 7.01279 8.65187 7.10759 9.07169C7.51023 10.8548 8.45481 12.6158 9.91948 14.0805C11.3842 15.5452 13.1452 16.4898 14.9283 16.8924C15.3481 16.9872 15.558 17.0346 15.8627 16.9708C16.2477 16.8902 16.7072 16.5229 16.8707 16.165C17 15.8819 17 15.5969 17 15.0267C17 14.5891 16.9512 14.084 16.4101 13.9992C15.0334 13.7834 14.3451 13.6756 14.1917 13.6845C13.5847 13.7201 13.1603 14.1738 12.7044 14.5409L11.6227 15.4118"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);

export default Footer;
