import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";
import {
  CheckCircle,
  Droplet,
  Shield,
  Sun,
  Sparkles,
  Clock,
  Wrench,
  ThumbsUp,
  Send,
} from "lucide-react";
import CeramicCoatingImg from "../assets/ceramic-coating.webp";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const CeramicCoatingPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
  });
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [dateError, setDateError] = useState("");

  useEffect(() => {
    // Set the minimum date to today
    const today = new Date();
    const minDateString = today.toISOString().split("T")[0];
    setMinDate(minDateString);

    // Set the maximum date to 6 months from now
    const maxDate = new Date(today.setMonth(today.getMonth() + 6));
    const maxDateString = maxDate.toISOString().split("T")[0];
    setMaxDate(maxDateString);
  }, []);

  const benefits = [
    { icon: <Shield className="w-6 h-6" />, text: "Long-lasting protection" },
    {
      icon: <Sparkles className="w-6 h-6" />,
      text: "Enhanced gloss and shine",
    },
    { icon: <Droplet className="w-6 h-6" />, text: "Hydrophobic properties" },
    {
      icon: <CheckCircle className="w-6 h-6" />,
      text: "Easier cleaning and maintenance",
    },
    {
      icon: <Sun className="w-6 h-6" />,
      text: "Protection against UV rays and oxidation",
    },
  ];

  const coatingProcess = [
    {
      icon: <Wrench className="w-6 h-6" />,
      text: "Thorough washing and decontamination",
    },
    {
      icon: <CheckCircle className="w-6 h-6" />,
      text: "Paint correction to remove imperfections",
    },
    {
      icon: <Droplet className="w-6 h-6" />,
      text: "Application of ceramic coating",
    },
    { icon: <Clock className="w-6 h-6" />, text: "Curing time (24-48 hours)" },
    {
      icon: <ThumbsUp className="w-6 h-6" />,
      text: "Final inspection and delivery",
    },
  ];

  const testimonials = [
    {
      name: "John D.",
      comment:
        "The ceramic coating made my car look better than new. It's been 6 months, and it still beads water like day one!",
    },
    {
      name: "Sarah M.",
      comment:
        "I was skeptical at first, but the results are amazing. My car is so much easier to clean now.",
    },
    {
      name: "Mike R.",
      comment:
        "The team was professional and the ceramic coating has protected my car from bird droppings and tree sap. Highly recommend!",
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "date") {
      validateDate(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateDate = (dateString) => {
    const selectedDate = new Date(dateString);
    const minDateObj = new Date(minDate);
    const maxDateObj = new Date(maxDate);

    if (isNaN(selectedDate.getTime())) {
      setDateError("Please enter a valid date.");
    } else if (selectedDate < minDateObj) {
      setDateError("Please select a date from today onwards.");
    } else if (selectedDate > maxDateObj) {
      setDateError("Please select a date within the next 6 months.");
    } else {
      setDateError("");
    }
  };

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "919924241719";
    const formattedDate = formatDate(formData.date);
    const message = `New Ceramic Coating Service Booking:
      Name: ${formData.name}
      Email: ${formData.email}
      Phone: ${formData.phone}
      Preferred Date: ${formattedDate}`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (dateError) {
      toast.error("Please correct the date error before submitting.");
      return;
    }
    handleWhatsAppClick();
    toast.success("Booking submitted successfully!");

    // Clear the form
    setFormData({
      name: "",
      email: "",
      phone: "",
      date: "",
    });
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <Helmet>
        <title>
          Best Ceramic-Coating Services in Ahmedabad - Arok Enterprise
        </title>
        <meta
          name="description"
          content="Discover the best ceramic-coating services in Ahmedabad at Arok Enterprise. Protect your vehicle with our professional and high-quality coatings."
        />
        <meta property="og:title" content="Ceramic Coating | Arok Enterprise" />
        <meta
          property="og:description"
          content="Protect your car's paint with high-quality ceramic coating services from Arok Enterprise, ensuring a long-lasting shine."
        />
        <meta
          property="og:url"
          content="https://www.arokenterprise.com/ceramic-coating"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ceramic Coating | Arok Enterprise"
        />
        <meta
          name="twitter:description"
          content="Get professional ceramic coating services at Arok Enterprise to keep your car's paint in pristine condition."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logo.png"
        />
        <link
          rel="canonical"
          href="https://www.arokenterprise.com/ceramic-coating"
        />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
        <Header />
        <Toaster position="top-center" reverseOrder={false} />
        <div
          className="relative min-h-screen bg-cover bg-center bg-fixed pt-16"
          style={{ backgroundImage: `url(${CeramicCoatingImg})` }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-70"></div>
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <motion.header
              initial="hidden"
              animate="visible"
              variants={fadeInUp}
              transition={{ duration: 0.6 }}
              className="text-center mb-12"
            >
              <h1 className="text-6xl font-bold mb-4 text-red-600">
                Ceramic Coating
              </h1>
              <p className="text-2xl text-gray-300">
                Protect Your Vehicle with Advanced Technology
              </p>
            </motion.header>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <div className="bg-gray-900 bg-opacity-80 rounded-lg p-6 mb-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <h2 className="text-3xl font-semibold text-red-500 mb-4">
                    What is Ceramic Coating?
                  </h2>
                  <p className="text-gray-300 mb-4">
                    Ceramic coating is a liquid polymer applied to a vehicle's
                    exterior. It chemically bonds with the vehicle's factory
                    paint, creating a layer of protection that enhances the
                    paint's durability and appearance.
                  </p>
                  <p className="text-gray-300">
                    This advanced technology provides a long-lasting, protective
                    layer that resists environmental contaminants, UV rays, and
                    chemical etching, keeping your vehicle looking pristine for
                    years to come.
                  </p>
                </div>

                <div className="bg-gray-900 bg-opacity-80 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <h2 className="text-3xl font-semibold mb-6 text-red-500">
                    Benefits of Ceramic Coating
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {benefits.map((benefit, index) => (
                      <motion.div
                        key={index}
                        className="flex items-center space-x-3"
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: index * 0.1 }}
                      >
                        <div className="text-red-500">{benefit.icon}</div>
                        <p className="text-gray-300">{benefit.text}</p>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{ duration: 0.6, delay: 0.4 }}
                className="bg-gray-900 bg-opacity-80 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <h2 className="text-3xl font-semibold text-red-500 mb-6">
                  Book Your Ceramic Coating Service
                </h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-300"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                      className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700 text-white p-2"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-300"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                      className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700 text-white p-2"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-300"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                      className="mt-1 block w-full rounded-md bg-gray-800 border-gray-700 text-white p-2"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-300"
                    >
                      Preferred Date
                    </label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      value={formData.date}
                      onChange={handleInputChange}
                      required
                      min={minDate}
                      max={maxDate}
                      className="mt-1 block w-full appearance-none rounded-md bg-gray-800 border-gray-700 text-white p-2"
                    />
                    {dateError && (
                      <p className="text-red-500 text-sm mt-1">{dateError}</p>
                    )}
                  </div>
                  <motion.button
                    type="submit"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors text-lg font-semibold flex items-center justify-center"
                    disabled={!!dateError}
                  >
                    <Send className="mr-2 w-5 h-5" />
                    Book Now via WhatsApp
                  </motion.button>
                </form>
              </motion.div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{ duration: 0.6, delay: 0.6 }}
                className="bg-gray-900 bg-opacity-80 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <h2 className="text-3xl font-semibold text-red-500 mb-6">
                  The Ceramic Coating Process
                </h2>
                <div className="space-y-4">
                  {coatingProcess.map((step, index) => (
                    <motion.div
                      key={index}
                      className="flex items-center space-x-3"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.1 }}
                    >
                      <div className="text-red-500">{step.icon}</div>
                      <p className="text-gray-300">{step.text}</p>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                transition={{ duration: 0.6, delay: 0.8 }}
                className="bg-gray-900 bg-opacity-80 rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <h2 className="text-3xl font-semibold text-red-500 mb-6">
                  Ceramic Coating vs. Traditional Waxing
                </h2>
                <table className="w-full text-gray-300">
                  <thead>
                    <tr>
                      <th className="text-left py-2">Feature</th>
                      <th className="text-center py-2">Ceramic Coating</th>
                      <th className="text-center py-2">Traditional Waxing</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2">Durability</td>
                      <td className="text-center">2-5 years</td>
                      <td className="text-center">2-3 months</td>
                    </tr>
                    <tr>
                      <td className="py-2">Hardness</td>
                      <td className="text-center">9H (pencil hardness)</td>
                      <td className="text-center">2H-4H</td>
                    </tr>
                    <tr>
                      <td className="py-2">Chemical Resistance</td>
                      <td className="text-center">High</td>
                      <td className="text-center">Low</td>
                    </tr>
                    <tr>
                      <td className="py-2">UV Protection</td>
                      <td className="text-center">Excellent</td>
                      <td className="text-center">Good</td>
                    </tr>
                    <tr>
                      <td className="py-2">Ease of Maintenance</td>
                      <td className="text-center">Very Easy</td>
                      <td className="text-center">Moderate</td>
                    </tr>
                  </tbody>
                </table>
              </motion.div>
            </div>

            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUp}
              transition={{ duration: 0.6, delay: 1 }}
              className="bg-gray-900 bg-opacity-80 rounded-lg p-6 mb-12 shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <h2 className="text-3xl font-semibold text-red-500 mb-6">
                Customer Testimonials
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {testimonials.map((testimonial, index) => (
                  <motion.div
                    key={index}
                    className="bg-gray-800 rounded-lg p-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <p className="text-gray-300 mb-2">
                      "{testimonial.comment}"
                    </p>
                    <p className="text-red-500 font-semibold">
                      - {testimonial.name}
                    </p>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUp}
              transition={{ duration: 0.6, delay: 1.2 }}
              className="text-center"
            >
              <h2 className="text-3xl font-semibold text-red-500 mb-4">
                Ready to Protect Your Vehicle?
              </h2>
              <p className="text-xl text-gray-300 mb-6">
                Experience the ultimate protection for your car's paint. Book
                your ceramic coating service today!
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() =>
                  document
                    .querySelector("form")
                    .scrollIntoView({ behavior: "smooth" })
                }
                className="bg-red-600 text-white px-8 py-3 rounded-lg hover:bg-red-700 transition-colors text-lg font-semibold"
              >
                Schedule Your Appointment
              </motion.button>
            </motion.div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CeramicCoatingPage;
