import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ChevronLeft, ShoppingCart, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useCart } from "../context/CartContext";
import { Toaster, toast } from "react-hot-toast";
import Header from "../components/Header";
import Footer from "../components/Footer";

const ProductInfo = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { addToCart, getCartItemCount } = useCart();
  const toastIdRef = React.useRef(null);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_SERVER_DOMAIN}/api/products/${productId}`
        );
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product", error);
        setError("Failed to load product. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleAddToCart = (product) => {
    addToCart({ ...product, quantity: 1 });

    if (toastIdRef.current) {
      toast.dismiss(toastIdRef.current);
    }

    toastIdRef.current = toast(
      (t) => (
        <div className="flex flex-col items-start bg-gray-800 text-white p-4 rounded-lg shadow-lg">
          <div className="flex items-center justify-between w-full mb-2">
            <span className="font-bold text-lg">Item Added to Cart</span>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="text-gray-400 hover:text-white transition-colors duration-200"
            >
              <X size={18} />
            </button>
          </div>
          <p className="mb-4">{product.name} has been added to your cart.</p>
          <div className="flex justify-between w-full">
            <button
              onClick={() => {
                navigate("/cart");
                toast.dismiss(t.id);
              }}
              className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
            >
              View Cart
            </button>
            <button
              onClick={() => toast.dismiss(t.id)}
              className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
            >
              Continue Shopping
            </button>
          </div>
        </div>
      ),
      {
        duration: 1500,
        style: {
          background: "transparent",
          boxShadow: "none",
          padding: 0,
        },
      }
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  if (!product) {
    return (
      <div className="text-gray-300 text-center mt-8">Product not found</div>
    );
  }

  return (
    <>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          className: "",
          style: {
            maxWidth: "400px",
            padding: "0",
          },
        }}
      />
      <Header cartItemCount={getCartItemCount()} />
      <section className="py-12 bg-gradient-to-b from-gray-900 to-black min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8">
          <div className="mb-8">
            <Link
              to={`/category/${product.category.urlName}/${product.subcategory._id}`}
              className="text-red-500 hover:text-red-600 transition duration-300 flex items-center"
            >
              <ChevronLeft className="w-5 h-5 mr-2" />
              <span>
                Back to {product.category.name} / {product.subcategory.name}
              </span>
            </Link>
          </div>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              className="grid grid-cols-1 md:grid-cols-2 gap-8"
            >
              <div>
                <img
                  src={product.images[0]}
                  alt={product.name}
                  className="w-full h-auto rounded-lg shadow-lg"
                />
              </div>
              <div>
                <h1 className="text-3xl font-bold text-red-500 mb-4">
                  {product.name}
                </h1>
                <p className="text-gray-300 mb-4">{product.description}</p>
                <div className="text-gray-300 mb-4">
                  <div className="text-gray-300 mb-4">
                    <p>Brand: {product.brand}</p>
                    <p>Stock: {product.stockQuantity} units</p>
                  </div>
                  <div className="text-gray-300 mb-4">
                    <h3 className="text-lg font-bold mb-2">Features:</h3>
                    <ul className="list-disc pl-4">
                      {product.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="text-gray-300 mb-4">
                    <h3 className="text-lg font-bold mb-2">
                      Compatible Vehicles:
                    </h3>
                    <ul className="list-disc pl-4">
                      {product.compatibleVehicles.map((vehicle, index) => (
                        <li key={index}>{vehicle}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-2xl font-bold text-white">
                    Rs. {product.price.toFixed(2)}
                  </span>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleAddToCart(product)}
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-all duration-300 flex items-center"
                  >
                    <ShoppingCart className="w-5 h-5 mr-2" />
                    <span>Add to Cart</span>
                  </motion.button>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProductInfo;
