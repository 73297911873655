import React, { useState, useEffect } from "react";
import { Menu, X, ShoppingCart } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCart } from "../context/CartContext";
import logo from "../assets/logo3.png";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { getCartItemCount, hasUnseenItems, markCartAsSeen } = useCart();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navItems = [
    {
      name: "Car Accessories",
      href: "/car-accessories",
      id: "car-accessories",
    },
    {
      name: "Ceramic coating",
      href: "/ceramic-coating",
      id: "ceramic-coating",
    },
    {
      name: "PPF",
      href: "/paint-protection-film",
      id: "paint-protection-film",
    },
    // {
    //   name: "Auto consultant",
    //   href: "/auto-consultant",
    //   id: "auto-consultant",
    // },
    {
      name: "Car Loan & insurance",
      href: "/car-loan-and-insurance",
      id: "car-loan-and-insurance",
    },
    {
      name: "Blogs",
      href: "/blogs",
      id: "blogs",
    },
    {
      name: "About",
      href: "/about-us",
      id: "about-us",
    },
    {
      name: "Contact Us",
      href: "/contact-us",
      id: "contact-us",
    },
  ];

  const handleNavClick = (href) => {
    navigate(href);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsHeaderVisible(false);
      } else {
        setIsHeaderVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cartItemCount = getCartItemCount();

  const handleCartClick = () => {
    markCartAsSeen();
    navigate("/cart");
  };

  return (
    <nav
      className={`text-white bg-transparent fixed w-full z-50 orbitron transition-transform duration-300 ${
        isHeaderVisible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center my-auto h-fit bg-white p-1.5 rounded-lg">
            <Link to="/">
              <img className="h-8 w-auto" src={logo} alt="Logo" rel="preload" />
            </Link>
          </div>

          <div className="hidden md:ml-6 md:flex md:space-x-4 lg:space-x-8">
            {navItems.map((item) => (
              <button
                key={item.id}
                onClick={() => handleNavClick(item.href)}
                className={`${
                  location.pathname === item.href
                    ? "border-red-600 text-red-600"
                    : "border-transparent text-white hover:border-red-600 hover:text-red-600"
                } inline-flex items-center px-1 pt-1 border-b-2 text-xs lg:text-sm font-medium`}
              >
                {item.name}
              </button>
            ))}
          </div>

          <div className="hidden md:ml-6 md:flex md:items-center">
            <button
              onClick={handleCartClick}
              className={`bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 relative ${
                hasUnseenItems ? "cart-icon-glow" : ""
              }`}
            >
              <ShoppingCart className="h-6 w-6" aria-hidden="true" />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {cartItemCount}
                </span>
              )}
            </button>
          </div>

          <div className="flex items-center md:hidden">
            <button
              onClick={toggleMenu}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-red-900 focus:outline-none bg-red-800"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <X className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`md:hidden bg-black/85 rounded-2xl ${
          isMenuOpen ? "block" : "hidden"
        }`}
      >
        <div className="pt-2 pb-3 space-y-1">
          {navItems.map((item) => (
            <button
              key={item.id}
              onClick={() => handleNavClick(item.href)}
              className={`${
                location.pathname === item.href
                  ? "bg-indigo-50 border-red-500 text-red-700"
                  : "border-transparent text-white hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
              } block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
            >
              {item.name}
            </button>
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4">
            <button
              onClick={handleCartClick}
              className={`flex-shrink-0 bg-white p-1 text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 relative ${
                hasUnseenItems ? "cart-icon-glow" : ""
              }`}
            >
              <ShoppingCart className="h-6 w-6" aria-hidden="true" />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                  {cartItemCount}
                </span>
              )}
            </button>
            <div className="ml-3">
              <div className="text-base font-medium text-white">Cart</div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
