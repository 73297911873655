import React, { useState } from "react";
import { motion } from "framer-motion";
import { ArrowRight } from "lucide-react";

const EnquiryForm = ({ type }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    enquiryType: type,
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Format the form data into a message string
    const message = `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nEnquiry Type: ${formData.enquiryType}\nMessage: ${formData.message}`;

    // Encode the message to be URL-friendly
    const encodedMessage = encodeURIComponent(message);

    // Create the WhatsApp URL
    const whatsappUrl = `https://wa.me/919924241719?text=${encodedMessage}`;

    // Open the WhatsApp URL in a new window
    window.open(whatsappUrl, "_blank");

    // Reset form after submission
    setFormData({
      name: "",
      email: "",
      phone: "",
      enquiryType: type,
      message: "",
    });

    // Show a success message to the user
    alert("Thank you for your enquiry. We will get back to you soon!");
  };

  return (
    <div className="mt-12">
      <h3 className="text-2xl font-semibold text-white mb-6">
        Submit Your {type === "loan" ? "Car Loan" : "Car Insurance"} Enquiry
      </h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-400"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm p-2 focus:outline-none"
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-400"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm p-2 focus:outline-none"
          />
        </div>
        <div>
          <label
            htmlFor="phone"
            className="block text-sm font-medium text-gray-400"
          >
            Phone
          </label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className="mt-1 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm p-2 focus:outline-none"
          />
        </div>
        <div>
          <label
            htmlFor="message"
            className="block text-sm font-medium text-gray-400"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            className="resize-none mt-1 block w-full rounded-md border border-gray-700 bg-gray-800 text-white shadow-sm focus:border-red-500 focus:ring-red-500 focus:outline-none sm:text-sm p-2"
          ></textarea>
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-red-600 text-white hover:bg-red-700 h-12 px-6 py-2"
        >
          Submit Enquiry <ArrowRight className="ml-2 h-4 w-4" />
        </motion.button>
      </form>
    </div>
  );
};

export default EnquiryForm;
