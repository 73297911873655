import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import EnquiryForm from "../components/EnquiryForm";
import {
  AlertCircle,
  Car,
  Shield,
  IndianRupee,
  Percent,
  Calendar,
  Info,
  ArrowRight,
  Umbrella,
  Wrench,
  ChevronDown,
  ChevronUp,
  Star,
  Check,
} from "lucide-react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";

const CarLoanAndInsurancePage = () => {
  const [activeTab, setActiveTab] = useState("loan");
  const [loanAmount, setLoanAmount] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeFAQ, setActiveFAQ] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const calculateLoan = () => {
    const principal = parseFloat(loanAmount);
    const monthlyRate = parseFloat(interestRate) / 100 / 12;
    const termMonths = parseFloat(loanTerm) * 12;

    const payment =
      (principal * monthlyRate * Math.pow(1 + monthlyRate, termMonths)) /
      (Math.pow(1 + monthlyRate, termMonths) - 1);
    setMonthlyPayment(payment.toFixed(2));
  };

  const tabVariants = {
    inactive: { opacity: 0.6, y: 5 },
    active: { opacity: 1, y: 0 },
  };

  const contentVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
  };

  const faqs = [
    {
      question: "What factors affect my car loan interest rate?",
      answer:
        "Several factors can affect your car loan interest rate, including your credit score, loan term, down payment amount, and the age of the vehicle you're purchasing.",
    },
    {
      question: "Can I pay off my car loan early?",
      answer:
        "Yes, most lenders allow you to pay off your car loan early. However, some may charge prepayment penalties, so it's important to check your loan agreement or contact your lender for details.",
    },
    {
      question:
        "What's the difference between a secured and unsecured car loan?",
      answer:
        "A secured car loan uses the vehicle as collateral, often resulting in lower interest rates. An unsecured car loan doesn't require collateral but typically has higher interest rates due to increased risk for the lender.",
    },
    {
      question: "How much should I put as a down payment on a car loan?",
      answer:
        "A good rule of thumb is to put down at least 20% of the car's value. This helps reduce your monthly payments and may help you qualify for better interest rates. However, the ideal down payment can vary based on your financial situation and the lender's requirements.",
    },
  ];

  const loanBenefits = [
    "Competitive interest rates",
    "Flexible repayment terms",
    "Quick approval process",
    "No hidden fees",
    "Option for pre-approval",
  ];

  const loanTypes = [
    {
      name: "New Car Loan",
      description:
        "Ideal for purchasing a brand new vehicle from a dealership.",
      interestRate: "Starting at 3.99% p.a.",
    },
    {
      name: "Used Car Loan",
      description:
        "Perfect for buying a pre-owned vehicle from a dealer or private seller.",
      interestRate: "Starting at 4.99% p.a.",
    },
    {
      name: "Refinance Loan",
      description:
        "Refinance your existing car loan to potentially lower your interest rate or monthly payments.",
      interestRate: "Starting at 3.49% p.a.",
    },
  ];

  const insurancePlans = [
    {
      name: "Basic",
      coverage: ["Third Party Liability", "Personal Accident Cover"],
      price: "$X/month",
    },
    {
      name: "Standard",
      coverage: [
        "Third Party Liability",
        "Own Damage",
        "Personal Accident Cover",
      ],
      price: "$Y/month",
    },
    {
      name: "Premium",
      coverage: [
        "Third Party Liability",
        "Own Damage",
        "Personal Accident Cover",
        "Zero Depreciation",
        "Engine Protection",
      ],
      price: "$Z/month",
    },
  ];

  const testimonials = [
    {
      name: "John D.",
      comment:
        "The car loan process was smooth and hassle-free. Got a great rate too!",
      rating: 5,
    },
    {
      name: "Sarah M.",
      comment:
        "Their comprehensive insurance saved me a lot of trouble after an accident. Highly recommended!",
      rating: 5,
    },
    {
      name: "Michael R.",
      comment:
        "The online quote system made it easy to compare different insurance options. Very user-friendly!",
      rating: 4,
    },
  ];

  const faqs2 = [
    {
      question: "What factors affect my car insurance premium?",
      answer:
        "Several factors can affect your car insurance premium, including your age, driving history, type of vehicle, location, and coverage options chosen.",
    },
    {
      question: "How can I lower my car insurance costs?",
      answer:
        "You can lower your car insurance costs by maintaining a good driving record, choosing a higher deductible, bundling policies, and taking advantage of available discounts such as safe driver or multi-car discounts.",
    },
    {
      question:
        "What's the difference between comprehensive and third-party insurance?",
      answer:
        "Third-party insurance covers damages to other people's property and injury to others in accidents you cause. Comprehensive insurance provides additional coverage for your own vehicle against theft, fire, and other non-collision related damages.",
    },
    {
      question: "How do I file a claim?",
      answer:
        "To file a claim, contact our 24/7 claims hotline or use our mobile app. You'll need to provide details about the incident, your policy number, and any relevant documentation such as police reports or photos of the damage.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Best Car-Loan-and-Insurance in Ahmedabad | Arok Enterprise
        </title>
        <meta
          name="description"
          content="Secure the best car-loan-and-insurance in Ahmedabad with Arok Enterprise. Get tailored solutions and expert guidance for your financial needs."
        />
        <meta
          property="og:title"
          content="Car Loan and Insurance | Arok Enterprise"
        />
        <meta
          property="og:description"
          content="Arok Enterprise provides comprehensive car loan and insurance services, making it easier to finance and protect your vehicle."
        />
        <meta
          property="og:url"
          content="https://www.arokenterprise.com/car-loan-and-insurance"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Car Loan and Insurance | Arok Enterprise"
        />
        <meta
          name="twitter:description"
          content="Secure your car with Arok Enterprise's reliable car loan and insurance services, tailored to meet your needs."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logo.png"
        />
        <link
          rel="canonical"
          href="https://www.arokenterprise.com/car-loan-and-insurance"
        />
      </Helmet>
      <Header />
      <div className="min-h-screen bg-black text-white pt-16 dotsBG">
        <div className="container mx-auto p-4 px-0.5 max-w-4xl">
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-4xl font-bold mb-8 text-center text-red-500"
          >
            Car Loan and Insurance Center
          </motion.h1>

          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="flex space-x-2 rounded-lg bg-gray-900 p-1 mb-8 shadow-lg"
          >
            <motion.button
              variants={tabVariants}
              animate={activeTab === "loan" ? "active" : "inactive"}
              className={`flex-1 justify-center items-center py-3 px-4 rounded-md text-sm font-medium transition-all ${
                activeTab === "loan"
                  ? "bg-red-600 text-white shadow-md"
                  : "text-gray-400 hover:text-white"
              }`}
              onClick={() => setActiveTab("loan")}
            >
              Car Loan
            </motion.button>
            <motion.button
              variants={tabVariants}
              animate={activeTab === "insurance" ? "active" : "inactive"}
              className={`flex-1 justify-center items-center py-3 px-4 rounded-md text-sm font-medium transition-all ${
                activeTab === "insurance"
                  ? "bg-red-600 text-white shadow-md"
                  : "text-gray-400 hover:text-white"
              }`}
              onClick={() => setActiveTab("insurance")}
            >
              Car Insurance
            </motion.button>
          </motion.div>

          <motion.div
            initial="hidden"
            animate="visible"
            variants={contentVariants}
            transition={{ duration: 0.5 }}
            className="rounded-lg border border-gray-800 bg-gray-900 text-white shadow-lg overflow-hidden"
          >
            <AnimatePresence mode="wait">
              {activeTab === "loan" && (
                <motion.div
                  key="loan"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  transition={{ duration: 0.3 }}
                  className="p-8"
                >
                  <h2 className="text-3xl font-semibold leading-none tracking-tight mb-4 text-red-500">
                    Car Loan Calculator
                  </h2>
                  <p className="text-sm text-gray-400 mb-6">
                    Plan your car purchase with our easy-to-use EMI calculator
                  </p>
                  <div className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="space-y-2">
                        <label
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-7"
                          htmlFor="loanAmount"
                        >
                          Loan Amount (₹)
                        </label>
                        <div className="relative">
                          <IndianRupee
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                            size={18}
                          />
                          <input
                            className="flex h-12 w-full rounded-md border border-gray-700 bg-gray-800 pl-10 pr-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50"
                            id="loanAmount"
                            type="text"
                            placeholder="e.g. 500000"
                            value={loanAmount}
                            onChange={(e) => setLoanAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="space-y-2">
                        <label
                          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          htmlFor="loanTerm"
                        >
                          Loan Term (Years)
                        </label>
                        <div className="relative">
                          <Calendar
                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                            size={18}
                          />
                          <input
                            className="flex h-12 w-full rounded-md border border-gray-700 bg-gray-800 pl-10 pr-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50"
                            id="loanTerm"
                            type="text"
                            placeholder="e.g. 5"
                            value={loanTerm}
                            onChange={(e) => setLoanTerm(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <label
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        htmlFor="interestRate"
                      >
                        Interest Rate (% p.a.)
                      </label>
                      <div className="relative">
                        <Percent
                          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                          size={18}
                        />
                        <input
                          className="flex h-12 w-full rounded-md border border-gray-700 bg-gray-800 pl-10 pr-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 disabled:cursor-not-allowed disabled:opacity-50"
                          id="interestRate"
                          type="text"
                          placeholder="e.g. 8.5"
                          value={interestRate}
                          onChange={(e) => setInterestRate(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="mt-8 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-red-600 text-white hover:bg-red-700 h-12 px-6 py-2 w-full"
                    onClick={calculateLoan}
                  >
                    Calculate EMI
                  </motion.button>
                  <AnimatePresence>
                    {monthlyPayment && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                        className="mt-8 p-6 bg-gray-800 rounded-md shadow-inner"
                      >
                        <p className="text-sm font-medium text-gray-300 mb-2">
                          Estimated Monthly EMI:
                        </p>
                        <p className="text-3xl font-bold text-red-500">
                          ₹{monthlyPayment}
                        </p>
                        <div className="mt-4 text-sm text-gray-400">
                          <p>Total Loan Amount: ₹{loanAmount}</p>
                          <p>Loan Term: {loanTerm} years</p>
                          <p>Interest Rate: {interestRate}% p.a.</p>
                        </div>
                        {/* <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="mt-4 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-red-600 text-white hover:bg-red-700 h-10 px-4 py-2"
                          onClick={() => navigate("/apply-loan")}
                        >
                          Apply for Loan <ArrowRight className="ml-2 h-4 w-4" />
                        </motion.button> */}
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      Our Car Loan Benefits
                    </h3>
                    <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {loanBenefits.map((benefit, index) => (
                        <li key={index} className="flex items-center space-x-2">
                          <Check className="h-5 w-5 text-green-500" />
                          <span>{benefit}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      Types of Car Loans
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                      {loanTypes.map((type, index) => (
                        <div key={index} className="bg-gray-800 p-6 rounded-lg">
                          <h4 className="text-xl font-semibold text-red-500 mb-2">
                            {type.name}
                          </h4>
                          <p className="text-sm text-gray-400 mb-2">
                            {type.description}
                          </p>
                          <p className="text-lg font-medium">
                            {type.interestRate}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      Frequently Asked Questions
                    </h3>
                    <div className="space-y-4">
                      {faqs.map((faq, index) => (
                        <div
                          key={index}
                          className="border border-gray-700 rounded-lg"
                        >
                          <button
                            className="flex justify-between items-center w-full p-4 text-left"
                            onClick={() =>
                              setActiveFAQ(activeFAQ === index ? null : index)
                            }
                          >
                            <span className="font-medium">{faq.question}</span>
                            {activeFAQ === index ? (
                              <ChevronUp className="h-5 w-5" />
                            ) : (
                              <ChevronDown className="h-5 w-5" />
                            )}
                          </button>
                          {activeFAQ === index && (
                            <div className="p-4 bg-gray-800">
                              <p className="text-gray-400">{faq.answer}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      Why Choose Our Car Loans?
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="bg-gray-800 p-6 rounded-lg">
                        <h4 className="text-xl font-semibold text-red-500 mb-4">
                          Fast Approval Process
                        </h4>
                        <p className="text-gray-400">
                          Our streamlined approval process ensures you get a
                          decision quickly, often within 24 hours.
                        </p>
                      </div>
                      <div className="bg-gray-800 p-6 rounded-lg">
                        <h4 className="text-xl font-semibold text-red-500 mb-4">
                          Competitive Rates
                        </h4>
                        <p className="text-gray-400">
                          We offer some of the most competitive interest rates
                          in the market, helping you save money over the life of
                          your loan.
                        </p>
                      </div>
                      <div className="bg-gray-800 p-6 rounded-lg">
                        <h4 className="text-xl font-semibold text-red-500 mb-4">
                          Flexible Terms
                        </h4>
                        <p className="text-gray-400">
                          Choose from a range of loan terms to find a monthly
                          payment that fits your budget.
                        </p>
                      </div>
                      <div className="bg-gray-800 p-6 rounded-lg">
                        <h4 className="text-xl font-semibold text-red-500 mb-4">
                          Excellent Customer Service
                        </h4>
                        <p className="text-gray-400">
                          Our dedicated team is here to support you throughout
                          the loan process and beyond.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      Ready to Get Started?
                    </h3>
                    <p className="text-gray-400 mb-6">
                      Take the first step towards owning your dream car. Apply
                      for a car loan today and let us help you drive away in
                      style.
                    </p>
                    {/* <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex-1 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-red-600 text-white hover:bg-red-700 h-12 px-6 py-2"
                        onClick={() => navigate("/apply-loan")}
                      >
                        Apply Now <ArrowRight className="ml-2 h-4 w-4" />
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex-1 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-gray-700 text-white hover:bg-gray-600 h-12 px-6 py-2"
                        onClick={() => navigate("/contact-us")}
                      >
                        Contact Us <ArrowRight className="ml-2 h-4 w-4" />
                      </motion.button>
                    </div> */}
                  </div>
                  <EnquiryForm type="loan" />
                </motion.div>
              )}

              {activeTab === "insurance" && (
                <motion.div
                  key="insurance"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  transition={{ duration: 0.3 }}
                  className="p-8"
                >
                  <h2 className="text-3xl font-semibold leading-none tracking-tight mb-4 text-red-500">
                    Car Insurance Options
                  </h2>
                  <p className="text-sm text-gray-400 mb-6">
                    Protect your vehicle with our comprehensive insurance
                    packages
                  </p>

                  {/* Existing insurance options */}

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      Compare Insurance Plans
                    </h3>
                    <div className="overflow-x-auto">
                      <table className="w-full text-sm text-left text-gray-400">
                        <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Plan
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Coverage
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Price
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {insurancePlans.map((plan, index) => (
                            <tr
                              key={index}
                              className="bg-gray-800 border-b border-gray-700"
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-white whitespace-nowrap"
                              >
                                {plan.name}
                              </th>
                              <td className="px-6 py-4">
                                <ul className="list-disc list-inside">
                                  {plan.coverage.map((item, i) => (
                                    <li key={i}>{item}</li>
                                  ))}
                                </ul>
                              </td>
                              <td className="px-6 py-4">{plan.price}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      Frequently Asked Questions
                    </h3>
                    <div className="space-y-4">
                      {faqs2.map((faq, index) => (
                        <div
                          key={index}
                          className="border border-gray-700 rounded-lg"
                        >
                          <button
                            className="flex justify-between items-center w-full p-4 text-left"
                            onClick={() =>
                              setActiveFAQ(activeFAQ === index ? null : index)
                            }
                          >
                            <span className="font-medium">{faq.question}</span>
                            {activeFAQ === index ? (
                              <ChevronUp className="h-5 w-5" />
                            ) : (
                              <ChevronDown className="h-5 w-5" />
                            )}
                          </button>
                          {activeFAQ === index && (
                            <div className="p-4 bg-gray-800">
                              <p className="text-gray-400">{faq.answer}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mt-12">
                    <h3 className="text-2xl font-semibold text-white mb-6">
                      What Our Customers Say
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                      {testimonials.map((testimonial, index) => (
                        <div key={index} className="bg-gray-800 p-6 rounded-lg">
                          <div className="flex items-center mb-4">
                            {[...Array(testimonial.rating)].map((_, i) => (
                              <Star
                                key={i}
                                className="h-5 w-5 text-yellow-400 fill-current"
                              />
                            ))}
                          </div>
                          <p className="text-gray-400 mb-2">
                            "{testimonial.comment}"
                          </p>
                          <p className="text-sm text-gray-500">
                            - {testimonial.name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* <motion.div
                    className="mt-12 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                  >
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="flex-1 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-red-600 text-white hover:bg-red-700 h-12 px-6 py-2"
                      onClick={() => navigate("/get-quote")}
                    >
                      Get a Quote <ArrowRight className="ml-2 h-4 w-4" />
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="flex-1 inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-gray-700 text-white hover:bg-gray-600 h-12 px-6 py-2"
                      onClick={() => navigate("/compare-plans")}
                    >
                      Compare Plans <ArrowRight className="ml-2 h-4 w-4" />
                    </motion.button>
                  </motion.div> */}
                  <EnquiryForm type="insurance" />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CarLoanAndInsurancePage;
