import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

const AboutPage = () => {
  const [activeTab, setActiveTab] = useState("story");

  const tabContent = {
    story: (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold text-red-600 mb-4">Our Story</h2>
        <p className="text-gray-300 mb-4">
          Founded in 2010, Arok Enterprise emerged from a passion for
          automobiles and a vision to revolutionize the car accessories market.
          What started as a small garage operation has now grown into a
          nationwide leader in automotive enhancement solutions.
        </p>
        <p className="text-gray-300 mb-4">
          Our journey has been fueled by innovation, quality, and an unwavering
          commitment to customer satisfaction. From introducing cutting-edge
          performance parts to designing sleek aesthetic upgrades, we've
          consistently pushed the boundaries of what's possible in car
          customization.
        </p>
      </motion.div>
    ),
    mission: (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold text-red-600 mb-4">Our Mission</h2>
        <p className="text-gray-300 mb-4">
          At Arok Enterprise, our mission is to transform every vehicle into a
          personalized masterpiece. We believe that your car is an extension of
          your personality, and we're here to help you express it.
        </p>
        <p className="text-gray-300 mb-4">We strive to:</p>
        <ul className="list-disc list-inside text-gray-300 mb-4">
          <li>Offer the highest quality car accessories and parts</li>
          <li>Provide expert guidance and support to our customers</li>
          <li>Stay at the forefront of automotive technology and trends</li>
          <li>
            Contribute to a more stylish, safe, and enjoyable driving experience
            for all
          </li>
        </ul>
      </motion.div>
    ),
    products: (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-2xl font-bold text-red-600 mb-4">Our Products</h2>
        <p className="text-gray-300 mb-4">
          Arok Enterprise offers a wide range of premium car accessories and
          parts, including:
        </p>
        <ul className="grid grid-cols-2 gap-4 text-gray-300">
          <li className="flex items-center">
            <span className="text-red-500 mr-2">►</span> Performance Upgrades
          </li>
          <li className="flex items-center">
            <span className="text-red-500 mr-2">►</span> Interior Accessories
          </li>
          <li className="flex items-center">
            <span className="text-red-500 mr-2">►</span> Exterior Styling Kits
          </li>
          <li className="flex items-center">
            <span className="text-red-500 mr-2">►</span> Audio Systems
          </li>
          <li className="flex items-center">
            <span className="text-red-500 mr-2">►</span> Lighting Solutions
          </li>
          <li className="flex items-center">
            <span className="text-red-500 mr-2">►</span> Safety Accessories
          </li>
        </ul>
      </motion.div>
    ),
  };

  return (
    <>
      <Helmet>
        <title>
          About AROK Enterprises | Your Trusted Partner in Car Accessories &
          Services
        </title>
        <meta
          name="description"
          content="Learn more about AROK Enterprises, our mission, values, and commitment to providing exceptional car accessories and services. Discover why we are the trusted choice for car enthusiasts."
        />
        <meta property="og:title" content="About Arok Enterprise" />
        <meta
          property="og:description"
          content="Learn more about Arok Enterprise, your trusted partner in automotive services, dedicated to providing quality solutions for your vehicle."
        />
        <meta
          property="og:url"
          content="https://www.arokenterprise.com/about-us"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://arokenterprise.com/assets/logo3-NqMEr2qw.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Arok Enterprise" />
        <meta
          name="twitter:description"
          content="Discover the story and mission behind Arok Enterprise, a leader in automotive services."
        />
        <meta
          name="twitter:image"
          content="https://arokenterprise.com/assets/logo.png"
        />
        <link rel="canonical" href="https://www.arokenterprise.com/about-us" />
      </Helmet>
      <div className="bg-gradient-to-t from-black to-gray-900 text-white min-h-screen">
        <Header />

        <main className="pt-20 px-4 sm:px-6 lg:px-8">
          <div className="max-w-6xl mx-auto">
            <motion.h1
              className="text-5xl font-extrabold text-center mb-12 text-red-600"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              About Arok Enterprise
            </motion.h1>

            <div className="mb-8 flex justify-center space-x-4">
              {["story", "mission", "products"].map((tab) => (
                <button
                  key={tab}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-300 ${
                    activeTab === tab
                      ? "bg-red-600 text-white"
                      : "bg-gray-800 text-gray-300 hover:bg-red-700"
                  }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </button>
              ))}
            </div>

            <div className="bg-gray-800 rounded-lg shadow-xl p-8 mb-12">
              {tabContent[activeTab]}
            </div>

            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h2 className="text-2xl font-bold text-red-600 mb-4">
                  Our Values
                </h2>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">✓</span> Quality
                  </li>
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">✓</span> Innovation
                  </li>
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">✓</span> Customer
                    Satisfaction
                  </li>
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">✓</span> Integrity
                  </li>
                </ul>
              </div>
              <div className="bg-gray-800 rounded-lg shadow-xl p-6">
                <h2 className="text-2xl font-bold text-red-600 mb-4">
                  Why Choose Us?
                </h2>
                <ul className="space-y-2 text-gray-300">
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">►</span> Expert advice
                    and support
                  </li>
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">►</span> Wide range of
                    premium products
                  </li>
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">►</span> Competitive
                    pricing
                  </li>
                  <li className="flex items-center">
                    <span className="text-red-500 mr-2">►</span> Fast and
                    reliable shipping
                  </li>
                </ul>
              </div>
            </motion.div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
