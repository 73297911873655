import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "../context/CartContext";
import Header from "../components/Header";
import { Minus, Plus, Trash2 } from "lucide-react";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";

const ConfirmationDialog = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl">
        <p className="text-white mb-4">{message}</p>
        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const CartPage = () => {
  const {
    cart,
    addToCart,
    removeFromCart,
    clearCart,
    getCartTotal,
    markCartAsSeen,
  } = useCart();
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [confirmAction, setConfirmAction] = useState(null);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  });

  useEffect(() => {
    markCartAsSeen();
  }, [markCartAsSeen]);

  const handleQuantityChange = (product, change) => {
    if (change === -1 && product.quantity === 1) {
      openConfirmDialog(
        `Are you sure you want to remove ${product.name} from your cart?`,
        () => removeItemFromCart(product)
      );
    } else {
      addToCart({ ...product, quantity: change });
      if (change > 0) {
        toast.success(`${product.name} added to cart`, {
          icon: "🛒",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      } else {
        toast.success(`${product.name} quantity decreased`, {
          icon: "🛒",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    }
  };

  const handleRemoveFromCart = (product) => {
    openConfirmDialog(
      `Are you sure you want to remove ${product.name} from your cart?`,
      () => removeItemFromCart(product)
    );
  };
  const removeItemFromCart = (product) => {
    removeFromCart(product._id);
    toast.error(`${product.name} removed from cart`, {
      icon: "🗑️",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  const handleClearCart = () => {
    openConfirmDialog(
      "Are you sure you want to clear your entire cart? This action cannot be undone.",
      () => {
        clearCart();
        toast.error("Cart cleared", {
          icon: "🗑️",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    );
  };

  const openConfirmDialog = (message, action) => {
    setConfirmDialogMessage(message);
    setConfirmAction(() => action);
    setIsConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setIsConfirmDialogOpen(false);
    setConfirmAction(null);
  };

  const handleConfirm = () => {
    if (confirmAction) {
      confirmAction();
    }
    closeConfirmDialog();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_SERVER_DOMAIN}/api/orders/create`,
        {
          products: cart.map((item) => ({
            product: item._id,
            quantity: item.quantity,
          })),
          totalAmount: getCartTotal(),
          shippingDetails: userDetails,
        }
      );

      const options = {
        key: import.meta.env.VITE_RAZORPAY_KEY_ID,
        amount: response.data.amount,
        currency: "INR",
        name: "Arok Enterprise Car Accessories",
        description: "Payment for your order",
        image:
          "https://scontent.famd5-4.fna.fbcdn.net/v/t39.30808-1/449930486_122150038820238155_2551439401709934522_n.jpg?stp=c45.0.200.200a_dst-jpg_p200x200&_nc_cat=111&ccb=1-7&_nc_sid=f4b9fd&_nc_ohc=lSIqQSxt08oQ7kNvgH-tZwJ&_nc_ht=scontent.famd5-4.fna&oh=00_AYAIgzc_nDG4HrG3pyWM65dn5tHVOpqsHNIJ08ioTYjwVw&oe=66B7C23F",
        order_id: response.data.id,
        handler: function (response) {
          axios
            .post(`${import.meta.env.VITE_SERVER_DOMAIN}/api/orders/verify`, {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            })
            .then(() => {
              toast.success("Payment successful!");
              clearCart();
              setShowCheckoutForm(false);
            })
            .catch(() => {
              toast.error("Payment verification failed.");
            });
        },
        prefill: {
          name: userDetails.name,
          email: userDetails.email,
          contact: userDetails.phone,
        },
        theme: {
          color: "#000000",
        },
        config: {
          display: {
            blocks: {
              banks: {
                name: "Pay via UPI or Cards",
                instruments: [
                  {
                    method: "upi",
                  },
                  {
                    method: "card",
                  },
                  {
                    method: "netbanking",
                  },
                  {
                    method: "wallet",
                  },
                ],
              },
            },
            sequence: ["block.banks"],
            preferences: {
              show_default_blocks: false,
            },
          },
        },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error("Error creating order. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <Toaster position="top-right" />
      <div className="bg-gray-900 min-h-screen pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <h1 className="text-3xl font-bold text-red-500 mb-8">Your Cart</h1>
          {cart.length === 0 ? (
            <div className="text-center">
              <p className="text-xl text-gray-300 mb-4">Your cart is empty</p>
              <Link
                to="/car-accessories"
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-all duration-300"
              >
                Continue Shopping
              </Link>
            </div>
          ) : (
            <>
              <div className="bg-gray-800 rounded-lg shadow-lg p-6 mb-8">
                {cart.map((item) => (
                  <div
                    key={item._id}
                    className="flex flex-col sm:flex-row items-center justify-between border-b border-gray-700 py-4 last:border-b-0"
                  >
                    <div className="flex items-center mb-4 sm:mb-0">
                      <img
                        src={item.images[0]}
                        alt={item.name}
                        className="w-20 h-20 object-cover rounded-lg mr-4"
                      />
                      <div>
                        <h2 className="text-xl font-bold text-white">
                          {item.name}
                        </h2>
                        <p className="text-gray-400">
                          Rs. {item.price.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <button
                        onClick={() => handleQuantityChange(item, -1)}
                        className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 mr-2"
                      >
                        <Minus size={16} />
                      </button>
                      <span className="text-white mx-2">{item.quantity}</span>
                      <button
                        onClick={() => handleQuantityChange(item, 1)}
                        className="bg-gray-700 hover:bg-gray-600 text-white rounded-full p-1 ml-2"
                      >
                        <Plus size={16} />
                      </button>
                      <button
                        onClick={() => handleRemoveFromCart(item)}
                        className="bg-red-600 hover:bg-red-700 text-white rounded-full p-1 ml-4"
                      >
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-center mt-8">
                <button
                  onClick={handleClearCart}
                  className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition-all duration-300 mb-4 sm:mb-0"
                >
                  Clear Cart
                </button>
                <div className="text-right">
                  <p className="text-xl text-white mb-2">
                    Total: Rs. {getCartTotal().toFixed(2)}
                  </p>
                  <button
                    onClick={() => setShowCheckoutForm(true)}
                    className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-all duration-300"
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>

              {showCheckoutForm && (
                <form
                  onSubmit={handleCheckout}
                  className="mt-8 bg-gray-800 p-6 rounded-lg"
                >
                  <h2 className="text-2xl font-bold text-white mb-4">
                    Shipping Details
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <input
                      type="text"
                      name="name"
                      value={userDetails.name}
                      onChange={handleInputChange}
                      placeholder="Full Name"
                      required
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                      type="email"
                      name="email"
                      value={userDetails.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      required
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                      type="tel"
                      name="phone"
                      value={userDetails.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                      required
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                      type="text"
                      name="address"
                      value={userDetails.address}
                      onChange={handleInputChange}
                      placeholder="Address"
                      required
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                      type="text"
                      name="city"
                      value={userDetails.city}
                      onChange={handleInputChange}
                      placeholder="City"
                      required
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                      type="text"
                      name="state"
                      value={userDetails.state}
                      onChange={handleInputChange}
                      placeholder="State"
                      required
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                    <input
                      type="text"
                      name="zipCode"
                      value={userDetails.zipCode}
                      onChange={handleInputChange}
                      placeholder="ZIP Code"
                      required
                      className="w-full p-2 rounded bg-gray-700 text-white"
                    />
                  </div>
                  <button
                    type="submit"
                    className="mt-4 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-all duration-300"
                  >
                    Place Order and Pay
                  </button>
                </form>
              )}
            </>
          )}
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={handleConfirm}
        message={confirmDialogMessage}
      />
    </>
  );
};

export default CartPage;
