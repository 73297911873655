import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CartProvider } from "./context/CartContext";
import HomePage from "./pages/HomePage";
import CarAccessoriesPage from "./pages/CarAccessoriesPage";
import SubcategoryPage from "./pages/SubcategoryPage";
import CategoryItemsPage from "./pages/CategoryItemsPage";
import CeramicCoatingPage from "./pages/CeramicCoatingPage";
import PaintProtectionFilmPage from "./pages/PaintProtectionFilmPage";
import AutoConsultantPage from "./pages/AutoConsultantPage";
import CarLoanAndInsurancePage from "./pages/CarLoanAndInsurancePage";
import CartPage from "./pages/CartPage";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import Form from "./components/Form";
import SuccessPage from "./pages/SuccessPage";
import ProductInfo from "./pages/ProductInfo";
import BlogsPage from "./pages/BlogsPage";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/car-accessories" element={<CarAccessoriesPage />} />
          <Route
            path="/category/:categoryUrlName"
            element={<SubcategoryPage />}
          />
          <Route
            path="/category/:categoryUrlName/:subcategoryUrlName"
            element={<CategoryItemsPage />}
          />
          <Route path="/product/:productId" element={<ProductInfo />} />
          <Route path="/ceramic-coating" element={<CeramicCoatingPage />} />
          <Route
            path="/paint-protection-film"
            element={<PaintProtectionFilmPage />}
          />
          {/* <Route path="/auto-consultant" element={<AutoConsultantPage />} /> */}
          <Route
            path="/car-loan-and-insurance"
            element={<CarLoanAndInsurancePage />}
          />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/about-us" element={<AboutPage />} />
          {/* <Route path="/apply-loan" element={<Form />} /> */}
          {/* <Route path="/success" element={<SuccessPage />} /> */}
          <Route path="/blogs" element={<BlogsPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
